import React from 'react'
import YACHT1 from "../../../Images/Yacht/Yacht1.jpg"
import YACHT2 from "../../../Images/Yacht/Yacht2.jpg"
import YACHT3 from "../../../Images/Yacht/Yacht3.jpg"
import YACHT4 from "../../../Images/Yacht/Yacht4.jpg"
import YACHT5 from "../../../Images/Yacht/Yacht5.jpg"
import YACHT6 from "../../../Images/Yacht/Yacht6.jpg"
import YACHT7 from "../../../Images/Yacht/Yacht7.jpg"
import YACHT8 from "../../../Images/Yacht/Yacht8.jpg"


const Gallary8 = () => {
  return (
    <div className="container p-0">
      <div className="row col-12 p-0 mx-auto gallary8">
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img src={YACHT1} alt="" className="tenday-gallary8-img" />
        </div>
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img src={YACHT2} alt="" className="tenday-gallary8-img" />
        </div>
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img src={YACHT3} alt="" className="tenday-gallary8-img" />
        </div>
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img src={YACHT4} className="tenday-gallary8-img" />
        </div>
      </div>
      <div className="row col-12 p-0 mx-auto gallary8">
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img src={YACHT5} alt="" className="tenday-gallary8-img" />
        </div>
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img src={YACHT6} alt="" className="tenday-gallary8-img" />
        </div>
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img src={YACHT7} alt="" className="tenday-gallary8-img" />
        </div>
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img src={YACHT8} alt="" className="tenday-gallary8-img" />
        </div>
      </div>
    </div>
  );
}

export default Gallary8