import React from 'react'
import { Accordion } from 'react-bootstrap';

const Itinery = () => {
  return (
    <div className="container my-4">
      <div className="row p-0 mx-auto col-12">
        <div className="col-12 col-md-12 p-0 mx-auto ">
          <div className="col-12 p-0 mx-auto d-flex my-2">
            <span className="yellow-span my-auto mr-1"></span>
            <h2 className="my-auto">Что вас ждет</h2>
          </div>
          <Accordion defaultActiveKey="0" alwaysOpen className="deco-text">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="deco-text">
                День 1 : Прилет, трансфер в Канди
              </Accordion.Header>
              <Accordion.Body>
                По прибытии в аэропорт Коломбо вас встретит водитель. Водитель
                отвезет вас к первому отелю Amaya Hills 5*, который находится
                среди зеленых холмов Канди. По пути в Канди ваш водитель
                остановится в самых интересных местах.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                День 2 : Канди, Амбулувава, Рамбода
              </Accordion.Header>
              <Accordion.Body>
                На 2-й день вас встретит наш русскоговорящий гид, и ваша
                экскурсия официально начнется. Сначала вы посетите самый большой
                тропический сад Азии - Королевский ботанический сад Перадения.
                Затем вас ждет мини-экскурсия по историческому городу – Канди.
                После обеда вы подниметесь на уникальную башню Амбулувава, за
                которой следует водопад Рамбода и чайные плантации. Вас ждет
                интересная мини-экскурсия по чайной фабрике и программа
                дегустации чая. Вторую ночь вы проведете в отеле Galway Heights
                4* в самом красивом городе острова - Нувара Элия.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>День 3 : Нувара Элия, Элла</Accordion.Header>
              <Accordion.Body>
                День 3 начинается с мини-экскурсии в Нувара-Элия, а затем вы
                направитесь в самый известный город холмов - Элла. Вам предстоит
                30-минутная поездка на легендарном поезде с открытыми дверями, а
                затем вы посетите исторический 9-арочный мост и маленький пик
                Адама. День 3 заканчивается в отеле Amaya Lake 5*.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                День 4 : Дамбулла, Сигирия, Пасикуда
              </Accordion.Header>
              <Accordion.Body>
                Утром 4-го дня ваш гид отвезет вас к Золотому храму Дамбуллы.
                Позже вы сможете покормить слонов и даже искупаться со слонами в
                реке. После обеда вы подниметесь на культовую Скальную крепость
                Сигирию, откуда сможете насладиться захватывающими дух видами,
                слушая рассказы гида. К вечеру вы доберетесь до конечного
                пункта, где голубой океан и белый песок - пляж Пасикуда. Остаток
                отпуска вы проведете в Amaya Beach 5* или любом другом отеле,
                который выберете из списка.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                День 5-10 : Океан, океан, океан{" "}
              </Accordion.Header>
              <Accordion.Body>
                Ваш пляжный отдых на пляжах Индийского океана начался. Пришло
                время насладиться сладкими кокосами, наблюдая за закатами на
                морском курорте. Во время вашего пребывания мы можем
                организовать туры по наблюдению за китами, уроки серфинга и
                многое другое.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>День 10 : Трансфер в аэропорт</Accordion.Header>
              <Accordion.Body>
                Ваш пляжный отдых на основе Индийского океана. Пришло время
                употребления сладких кокосов, наблюдая за закатами в морском
                курорте. Во время наблюдения вашего наблюдения мы собираем туры
                по наблюдению за китами, уроки серфинга и многое другое.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="col-12 col-md-1 p-0 mx-auto"></div>
        <div className="col-12 col-md-4 p-0 mx-auto"></div>
      </div>
    </div>
  );
}

export default Itinery