import React from 'react'
import "./Footer.css";
import { Link } from "react-router-dom";
import { FaMapMarkerAlt, FaPhoneAlt, FaGlobe, FaInstagram, FaTelegram, FaWhatsapp } from "react-icons/fa";


const Footer = () => {
  return (
    <div className="row col-12 p-0 mx-auto">
      {/* <h2 className="my-3 deco-text text-center font-800 p-0">Контакты</h2> */}
      <footer class="footer-distributed mt-4">
        <div className="container">
          <div class="footer-left">
            <h3>
              YAMU<span>tours</span>
            </h3>

            <p class="footer-links">
              <Link to="/" class="link-1">
                Главная
              </Link>

              <Link to="/excursions">Экскурсии</Link>

              <Link to="/tourbuilder">Туры</Link>

              <Link to="/aboutus">О нас </Link>
            </p>

            <p class="footer-company-name text-light">Yamutours © 2022</p>
          </div>

          <div class="footer-center">
            <div className="mt-3">
              <FaMapMarkerAlt size={35} className="blue-text" />
              <p className="ml-2">
                <span>163/13 A, Калугалла Мавата,</span> Кэгалл, Шри-Ланка
              </p>
            </div>

            <div className="mt-3">
              <FaPhoneAlt size={30} className="blue-text" />
              <p className="ml-2">+7 929 986 53 82</p> 
            </div>

            <div className="mt-3">
              <FaGlobe size={35} className="blue-text" />
              <p className="ml-2">
                <Link to="/">www.yamutours.ru</Link>  
              </p>
            </div>
          </div>

          <div class="footer-right">
            <p class="footer-company-about">
              <span>Yamu Tours</span>
              Индивидуальные и групповые туры с русскоговорящими гидами по
              Шри-Ланке.
            </p>

            <div class="footer-icons">
              <a href="https://wa.me/79299865382">
                <FaWhatsapp />
              </a>
              <a href="https://instagram.com/yamutours">
                <FaInstagram />
              </a>
              <a href="https://t.me/yamutours1">
                <FaTelegram />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer