import React from "react";
import Footer from "../Maipage.js/Footer";
import Builder from "./Builder";
import Hero from "./Hero";

const Tourbuilder = () => {
  return (
    <div>
      <Hero />
          <Builder />
          <Footer/>
    </div>
  );
};

export default Tourbuilder;
