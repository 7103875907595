import React from 'react'
import Accordion from "react-bootstrap/Accordion";
import { FaCheck, FaTelegramPlane, FaWhatsapp } from "react-icons/fa";
import { AiFillPhone } from "react-icons/ai";
// import Card from "react-bootstrap/Card";
// import Carousel from "react-bootstrap/Carousel";

const Details = () => {
    return (
      <div className="container d-flex">
        <div className="col-12 my-3 p-1 row mx-auto">
          <div className="col-12 col-md-3 row p-1 mx-auto">
            <div className="col-12  d-flex border-thin">
              <div className="col-12">
                <h5 className="text-center my-auto">Продолжительность</h5>
                <p className="text-center my-auto">5-6 часов</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 row p-1 mx-auto">
            <div className="col-12  d-flex border-thin"> 
              <div className="col-12">
                <h5 className="text-center my-auto">Стоимость</h5>
                <p className="text-center my-auto">от $55</p>
              </div> 
            </div>
          </div>
          <div className="col-12 col-md-3 row p-1 mx-auto ">
            <div className="col-12  d-flex border-thin">
              <div className="col-12"> 
                <h5 className="text-center my-auto">Групповые туры</h5>
                <p className="text-center my-auto">По вторникам и субботам</p>
              </div>
            </div>
          </div>
          <div className="p-1 my-3">
            <h2 className="mt-2 deco-text">Обзор</h2>
            <p className="deco-text">
              Национальный парк Кумана (Kumana National Park) расположен на
              юго-востоке Шри-Ланки, в 40 км от Аругам-бей. Он имеет обширные
              угодья, способные обеспечить пропитанием большое количество
              находящихся под угрозой водно-болотных видов животных, в том числе
              редких видов черепах, таких как Зеленая черепаха и черепаха
              Каретта. Из других исчезающих видов рептилий здесь обитает
              болотный крокодил и индийская черная черепаха. Помимо рептилий в
              парке обитают такие крупные млекопитающие как слоны, буйволы,
              леопарды, кабаны, олени, шакалы, а также более мелкие выдры и
              кошки-рыбаловы.
            </p>
          </div>

          <div className="p-1 my-3">
            <h2 className="mt-2 deco-text">Маршрут</h2>
            <Accordion defaultActiveKey="0" className="deco-text">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="deco-text">
                  Выезд 14:00
                </Accordion.Header>
                <Accordion.Body>
                  <h5 className="deco-text">Виски пойнт : 13.30</h5>
                  <h5 className="deco-text">Панама : 13.45</h5>
                  <h5 className="deco-text">Аругам-Бей : 14.00</h5>
                  Водитель прибудет в ваш отель или на вашу виллу, чтобы забрать
                  вас. Если водитель не может найти ваш отель с помощью карт
                  Google, он может позвонить вам. Вы должны оплатить полную
                  сумму при встрече с водителем.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Национальный парк Кумана 15:00
                </Accordion.Header>
                <Accordion.Body>
                  Вы прибудете ко входу в парк около 15:00. Ваш водитель купит
                  вам входные билеты в кассе. После покупки билетов начинается
                  ваше сафари. Водитель покажет вам как можно больше диких
                  животных. Выходить из джипа нельзя без разрешения водителя.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Куда Кэбилитта 16:30</Accordion.Header>
                <Accordion.Body>
                  Остановка в 15 минутах возле берега реки. Вам предложат
                  закуски, фрукты и воду.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Выезд из сафари-парка 18:30</Accordion.Header>
                <Accordion.Body>
                  Сафари заканчивается около 18.30. Ваш водитель отвезет вас в
                  отель.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="p-1 row col-12 deco-text my-3">
            <h2 className="mt-2">Включено / Не включено</h2>
            <div className=" col-12">
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Сафари джип</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Опытный водитель</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Входные билеты</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Закуски и вода</p>
              </div>
            </div>
            {/* <div className="col-6">
              <div className="col-12 d-flex">
                <FaTimes className="my-auto" />{" "}
                <p className="my-auto ml-2">Transport</p>
              </div>
              <div className="col-12 d-flex">
                <FaTimes className="my-auto" />{" "}
                <p className="my-auto ml-2">Transport</p>
              </div>
            </div> */}
          </div>
          {/* <div className="p-1 deco-text my-3">
            <h2 className="mt-2">Gallary</h2>
            <div className="row col-12 p-0 mx-auto">
              <div className="col-6 col-md-3 p-1 mx-auto">
                <Card className="card-width">
                  <Card.Img
                    variant="top"
                    src="https://images.unsplash.com/photo-1578517929034-db013fd86597?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                  />
                  <Card.Body>
                    <p className="text-center my-auto">Nuwara Eliya</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-6 col-md-3 p-1 mx-auto">
                <Card>
                  <Card.Img
                    variant="top"
                    src="https://images.unsplash.com/photo-1578517929034-db013fd86597?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                  />
                  <Card.Body>
                    <p className="text-center my-auto">Nuwara Eliya</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-6 col-md-3 p-1 mx-auto">
                <Card>
                  <Card.Img
                    variant="top"
                    src="https://images.unsplash.com/photo-1578517929034-db013fd86597?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                  />
                  <Card.Body>
                    <p className="text-center my-auto">Nuwara Eliya</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-6 col-md-3 p-1 mx-auto">
                <Card>
                  <Card.Img
                    variant="top"
                    src="https://images.unsplash.com/photo-1578517929034-db013fd86597?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                  />
                  <Card.Body>
                    <p className="text-center my-auto">Nuwara Eliya</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-6 col-md-3 p-1 mx-auto">
                <Card>
                  <Card.Img
                    variant="top"
                    src="https://images.unsplash.com/photo-1578517929034-db013fd86597?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                  />
                  <Card.Body>
                    <p className="text-center my-auto">Nuwara Eliya</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-6 col-md-3 p-1 mx-auto">
                <Card>
                  <Card.Img
                    variant="top"
                    src="https://images.unsplash.com/photo-1578517929034-db013fd86597?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                  />
                  <Card.Body>
                    <p className="text-center my-auto">Nuwara Eliya</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-6 col-md-3 p-1 mx-auto">
                <Card>
                  <Card.Img
                    variant="top"
                    src="https://images.unsplash.com/photo-1578517929034-db013fd86597?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                  />
                  <Card.Body>
                    <p className="text-center my-auto">Nuwara Eliya</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-6 col-md-3 p-1 mx-auto">
                <Card>
                  <Card.Img
                    variant="top"
                    src="https://images.unsplash.com/photo-1578517929034-db013fd86597?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                  />
                  <Card.Body>
                    <p className="text-center my-auto">Nuwara Eliya</p>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div> */}
          {/* <div className="p-1 deco-text my-3">
            <h2 className="mt-2">Reviews</h2>
            <div className="col-12 col-md-6 p-0 mx-auto">
              <Carousel variant="dark">
                <Carousel.Item>
                  <div className="review-box row col-12 py-5">
                    <div className="d-flex col-12">
                      <div className="client mx-auto"></div>
                    </div>
                    <div className="col-8 mx-auto">
                      {" "}
                      <p className="text-center">Michael Stevens</p>
                    </div>
                    <div className="col-8 mx-auto">
                      <p className="text-center">
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Eum aperiam quis dolore vitae ut tempore
                        necessitatibus facere. Rem fugiat ullam tempore
                        asperiores corporis dolor, cupiditate facere dicta
                        corrupti modi reiciendis.
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="review-box row col-12 py-5">
                    <div className="d-flex col-12">
                      <div className="client mx-auto"></div>
                    </div>
                    <div className="col-8 mx-auto">
                      {" "}
                      <p className="text-center">Michael Stevens</p>
                    </div>
                    <div className="col-8 mx-auto">
                      <p className="text-center">
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Eum aperiam quis dolore vitae ut tempore
                        necessitatibus facere. Rem fugiat ullam tempore
                        asperiores corporis dolor, cupiditate facere dicta
                        corrupti modi reiciendis.
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="review-box row col-12 py-5">
                    <div className="d-flex col-12">
                      <div className="client mx-auto"></div>
                    </div>
                    <div className="col-8 mx-auto">
                      {" "}
                      <p className="text-center">Michael Stevens</p>
                    </div>
                    <div className="col-8 mx-auto">
                      <p className="text-center">
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Eum aperiam quis dolore vitae ut tempore
                        necessitatibus facere. Rem fugiat ullam tempore
                        asperiores corporis dolor, cupiditate facere dicta
                        corrupti modi reiciendis.
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </div> */}
          <div className="p-1 deco-text my-3">
            <h2 className="mt-2">Свяжитесь с нами</h2>
            <div className="col-6 p-0">
              <div className="col-12 d-flex">
                <AiFillPhone className="my-auto" />{" "}
                <p className="my-auto ml-2">+7 929 986 53 82</p>
              </div>
              <div className="col-12 d-flex">
                <FaWhatsapp className="my-auto" />{" "}
                <p className="my-auto ml-2">+7 929 986 53 82</p>
              </div>
              <div className="col-12 d-flex">
                <FaTelegramPlane className="my-auto" />{" "}
                <p className="my-auto ml-2">@yamutours1</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Details