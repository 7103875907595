import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { FaCheck, FaTimes } from "react-icons/fa";
// import Photogrid from "./Photogrid";
import Pricetable from "./Pricetable"
import Booktour from "../../Minicomponents/Booktour";
import Gallary8 from "./Gallary8";

const Details = () => {
  return (
    <div className="container d-flex p-0">
      <div className="col-12 mt-3 p-0 row mx-auto">
        <div className="col-12 col-md-3 row p-1 mx-auto">
          <div className="col-12  d-flex border-thin">
            <div className="col-12">
              <h5 className="text-center my-auto">Продолжительность</h5>
              <p className="text-center my-auto">7 часов</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 row p-1 mx-auto">
          <div className="col-12  d-flex border-thin">
            <div className="col-12">
              <h5 className="text-center my-auto">Стоимость</h5>
              <p className="text-center my-auto">от $60</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 row p-1 mx-auto ">
          <div className="col-12  d-flex border-thin">
            <div className="col-12">
              <h5 className="text-center my-auto">Групповый тур</h5>
              <p className="text-center my-auto">По Понидельникам</p>
            </div>
          </div>
        </div>
        <div className="p-1 my-3">
          <h2 className="mt-2 deco-text">Обзор</h2>
          <p className="deco-text">
            Отправьтесь в захватывающий сафари-тур на джипе в экологический парк
            Хурулу на Шри Ланке. Исследуйте богатое биологическое разнообразие
            парка, включая слоны и различные виды птиц, во время захватывающего
            путешествия под руководством опытных водителей в дикой природе.
          </p>
        </div>

        <div className="p-1 my-3">
          <h2 className="mt-2 deco-text">Маршрут</h2>
          <Accordion defaultActiveKey="0" alwaysOpen className="deco-text">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="deco-text">
                Выезд 12:30
              </Accordion.Header>
              <Accordion.Body>
                Водитель прибудет в ваш отель или на вашу виллу, чтобы забрать
                вас. Если водитель не может найти ваш отель с помощью карт
                Google, он может позвонить вам.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Сафари в национальном парке Миннерия или Хурулу Эко
              </Accordion.Header>
              <Accordion.Body>
                Ваш сафари-водитель встретит вас у входа в национальный парк и
                покажет вам как можно больше животных и интересных особенностей
                парка за три часа.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>Возвращение в отель (20:00)</Accordion.Header>
              <Accordion.Body>
                Ваш водитель отвезет вас в отель примерно в 20:00.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="p-1 row col-12 deco-text my-3">
          <h2 className="mt-2">Включено / Не включено</h2>
          <div className=" col-12 p-0">
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Транспорт </p>
            </div>
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Опытный сафари водитель</p>
            </div>
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Сафари джип</p>
            </div>
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Входные билеты</p>
            </div>
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Закуски / напитки</p>
            </div>
          </div>
          <div className="col-12 p-0">
            <div className="col-12 d-flex">
              <FaTimes className="my-auto" />{" "}
              <p className="my-auto ml-2">Обед</p>
            </div>
            <div className="col-12 d-flex">
              <FaTimes className="my-auto" />{" "}
              <p className="my-auto ml-2">Личные расходы</p>
            </div>
          </div>
        </div>
        <div className="p-1 col-12 mx-auto deco-text my-3">
          <h2 className="mt-2">Стоимость</h2>
          <div className="col-12 p-0">
            <Pricetable />
          </div>
        </div>
        <div className="p-1 col-12 mx-auto deco-text my-3">
          <h2 className="mt-2">Галерея</h2>
          <div className="col-12 p-0">
            <Gallary8 />
          </div>
        </div>
        <div className="p-1 row col-12 deco-text my-3 mx-auto">
          <div className="p-0 col-12">
            <Booktour tour="Хурулу Парк Сафари" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
