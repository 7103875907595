import React from "react";
import Gallery from "react-grid-gallery";


const IMAGES = [
  {
    src: "https://live.staticflickr.com/7060/6987848399_f4e7447d68_h.jpg",
    thumbnail: "https://live.staticflickr.com/7060/6987848399_f4e7447d68_h.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 212,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://live.staticflickr.com/4552/26989886119_3180b823ca_h.jpg",
    thumbnail:
      "https://live.staticflickr.com/4552/26989886119_3180b823ca_h.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 212,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://live.staticflickr.com/65535/49262111543_90df24544a_h.jpg",
    thumbnail:
      "https://live.staticflickr.com/65535/49262111543_90df24544a_h.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 212,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://live.staticflickr.com/965/27809773168_9dc0e8d01d_h.jpg",
    thumbnail: "https://live.staticflickr.com/965/27809773168_9dc0e8d01d_h.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 212,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://live.staticflickr.com/5583/30266208865_e6095983d9_h.jpg",
    thumbnail:
      "https://live.staticflickr.com/5583/30266208865_e6095983d9_h.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 212,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://live.staticflickr.com/2869/12632864113_258a022f53_h.jpg",
    thumbnail:
      "https://live.staticflickr.com/2869/12632864113_258a022f53_h.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 212,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://live.staticflickr.com/2897/33127653392_8edb59ed2b_h.jpg",
    thumbnail:
      "https://live.staticflickr.com/2897/33127653392_8edb59ed2b_h.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 212,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://live.staticflickr.com/7503/16248794772_978e795ae3_b.jpg",
    thumbnail:
      "https://live.staticflickr.com/7503/16248794772_978e795ae3_b.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 212,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://live.staticflickr.com/809/25956460877_ea4e517569_h.jpg",
    thumbnail:
      "https://live.staticflickr.com/809/25956460877_ea4e517569_h.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 212,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://live.staticflickr.com/65535/50271555211_1341c84266_h.jpg",
    thumbnail:
      "https://live.staticflickr.com/65535/50271555211_1341c84266_h.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 212,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
];

const Photogrid = () => {
  return <Gallery images={IMAGES} />;
};

export default Photogrid;
