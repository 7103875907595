import React from 'react'
import Accordion from "react-bootstrap/Accordion";
import { FaCheck,  FaTimes,  } from "react-icons/fa";


import Photogrid from './Photogrid';
import Pricetable from './Pricetable';
import Booktour from '../../Minicomponents/Booktour';

const Details = () => {
    return (
      <div className="container p-0 d-flex">
        <div className="col-12 my-3 p-0 row mx-auto">
          <div className="col-12 col-md-3 row p-1 mx-auto">
            <div className="col-12  d-flex border-thin">
              <div className="col-12">
                <h5 className="text-center my-auto">Продолжительность</h5>
                <p className="text-center my-auto">6 часов</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 row p-1 mx-auto">
            <div className="col-12  d-flex border-thin">
              <div className="col-12">
                <h5 className="text-center my-auto">Стоимость</h5>
                <p className="text-center my-auto">от $50</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 row p-1 mx-auto ">
            <div className="col-12  d-flex border-thin">
              <div className="col-12">
                <h5 className="text-center my-auto">Групповые туры</h5>
                <p className="text-center my-auto">По понедельникам</p>
              </div>
            </div>
          </div>
          <div className="p-1 my-3">
            <h2 className="mt-2 deco-text">Обзор</h2>
            <p className="deco-text">
              Сафари-парк Ридиягама — первый в Шри-Ланке рукотворный
              сафари-парк, открывший свои двери для публики в 2016 году. Он был
              построен для туризма при содействии Национального зоологического
              департамента Шри-Ланки. В парке, занимающем площадь 500 акров,
              обитают 22 вида животных, в основном млекопитающие и птицы.
            </p>
          </div>

          <div className="p-1 my-3">
            <h2 className="mt-2 deco-text">Маршрут</h2>
            <Accordion defaultActiveKey="0" alwaysOpen className="deco-text">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="deco-text">
                  Выезд 13:00
                </Accordion.Header>
                <Accordion.Body>
                  Водитель прибудет в ваш отель или на вашу виллу, чтобы забрать
                  вас. Если водитель не может найти ваш отель с помощью карт
                  Google, он может позвонить вам. Вы должны оплатить полную
                  сумму при встрече с водителем.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="deco-text">
                  Встреча с водителем сафари
                </Accordion.Header>
                <Accordion.Body>
                  Вы встретитесь с водителем сафари-джипа в городке
                  Тиссамахарама.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Сафари парк Ридиягама</Accordion.Header>
                <Accordion.Body>
                  Вы прибудете ко входу в парк около 15:00. Ваш водитель купит
                  вам входные билеты в кассе. После покупки билетов начинается
                  ваше сафари. Водитель покажет вам как можно больше диких
                  животных.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Кормление слонов </Accordion.Header>
                <Accordion.Body>
                  После сафари покормите слона, который живет в питомнике
                  сафари-парка.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Рыботерапия</Accordion.Header>
                <Accordion.Body>
                  Погрузите ноги в специальный бассейн, и пусть сотни рыбок
                  сделают вам естественный массаж стоп
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>Кормление попугаев</Accordion.Header>
                <Accordion.Body>
                  Зайдите в птичий домик сафари-парка Ридиягама и покормить
                  попугаев и других птиц из собственных рук.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>Возвращение в ваш отель</Accordion.Header>
                <Accordion.Body>
                  Экскурсия заканчивается около 18:30. Ваш водитель отвезет вас
                  в отель.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="p-1 row col-12 deco-text my-3">
            <h2 className="mt-2">Включено / Не включено</h2>
            <div className=" col-12">
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Трансфер со всеми расходамы</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Сафари джип</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Опытный сафари водитель</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Входные билеты</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Закуски, фрукты и вода</p>
              </div>
            </div>
            <div className="col-12">
              <div className="col-12 d-flex">
                <FaTimes className="my-auto" />{" "}
                <p className="my-auto ml-2">Кормление животных ($5/чел)</p>
              </div>
            </div>
          </div>
          <div className="p-1 deco-text my-3">
            <h2 className="mt-2">Gallary</h2> 
            <Photogrid />
          </div>
          <div className="p-1 col-12 mx-auto deco-text my-3">
            <h2 className="mt-2">Стоимость</h2>
            <div className="col-12 p-0">
              <Pricetable />
            </div>
          </div>
          <div className="p-1 row col-12 deco-text my-3 mx-auto">
            <div className="p-0 col-12">
              <Booktour tour="Ридиягама джип сафари - Yamu Safari Ridiyagama" />
            </div>
          </div>
        </div>
      </div>
    );
}

export default Details