import React from 'react'
import Table from "react-bootstrap/Table";

const Pricetable = () => {
  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Количество Туристов</th>
            <th>Стоимость</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Групповый тур <br /> (По средам)
            </td>
            <td>
              $140 / чел*** <br />
              (при одноместном размещении доплата - $30)
            </td>
          </tr>
          <tr>
            <td>
              Индивидуальный тур <br />
              (До 4-х взрослых)
            </td>
            <td>$280 / чел</td>
          </tr>
          <tr>
            <td>
              Индивидуальный тур <br />
              (4 - 5 взрослых )
            </td>
            <td>$230 / чел</td>
          </tr>
          <tr>
            <td>
              Индивидуальный тур <br />
              (От 5-и человек )
            </td>
            <td>от $165 / чел</td>
          </tr>
        </tbody>
      </Table>
      <p>*Возможно только на индивидуальных экскурсиях.</p>
      <p>
        **Если в отелях в Рамбоде нет свободных номеров, мы забронируем
        аналогичный отель в Канди или Нувара Элия.
      </p>
      <p>
        ***В случае, если группа (5 чел) не наберется, организуем
        индивидуальную экскурсию по скидочной цене.
      </p>
    </div>
  );
}

export default Pricetable