import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { FaCheck, FaTimes } from "react-icons/fa";
// import Photogrid from "./Photogrid";
import Pricetable from "./Pricetable"
import Booktour from "../../Minicomponents/Booktour";
import Gallary8 from "./Gallary8";

const Details = () => {
  return (
    <div className="container d-flex p-0">
      <div className="col-12 mt-3 p-0 row mx-auto">
        <div className="col-12 col-md-3 row p-1 mx-auto">
          <div className="col-12  d-flex border-thin">
            <div className="col-12">
              <h5 className="text-center my-auto">Продолжительность</h5>
              <p className="text-center my-auto">14 часов</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 row p-1 mx-auto">
          <div className="col-12  d-flex border-thin">
            <div className="col-12">
              <h5 className="text-center my-auto">Стоимость</h5>
              <p className="text-center my-auto">от $60</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 row p-1 mx-auto ">
          <div className="col-12  d-flex border-thin">
            <div className="col-12">
              <h5 className="text-center my-auto">Групповый тур</h5>
              <p className="text-center my-auto">По средам и пятницам</p>
            </div>
          </div>
        </div>
        <div className="p-1 my-3">
          <h2 className="mt-2 deco-text">Обзор</h2>
          <p className="deco-text">
            В Элле собрано все лучшее, что есть на Шри-Ланке: красивые горы в
            джунглях, живописные чайные плантации и эпические водопады. Являясь
            одним из крупнейших производителей чая, путешественники могут с
            нетерпением ждать возможности провести время среди зелени,
            наслаждаясь видами, открывающимися с увлекательных пешеходных
            маршрутов.
          </p>
        </div>

        <div className="p-1 my-3">
          <h2 className="mt-2 deco-text">Маршрут</h2>
          <Accordion defaultActiveKey="0" alwaysOpen className="deco-text">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="deco-text">
                Выезд 02:30
              </Accordion.Header>
              <Accordion.Body>
                <h5 className="deco-text">Хиккадува : 02.30</h5>
                <h5 className="deco-text">Унаватуна : 03.00</h5>
                <h5 className="deco-text">Вэлигама / Мирисса : 03.30</h5>
                <h5 className="deco-text">Тангалла : 04.00</h5>
                Водитель прибудет в ваш отель или на вашу виллу, чтобы забрать
                вас. Если водитель не может найти ваш отель с помощью карт
                Google, он может позвонить вам.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Водапад Равана</Accordion.Header>
              <Accordion.Body>
                Водопад Равана назван в честь короля демонов Раваны, который,
                согласно традиционному фольклору, правил Шри-Ланкой.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Поезд (30 минут)</Accordion.Header>
              <Accordion.Body>
                Самая живописная поездка на поезде в мире через чайные
                плантации, водопады, горы и фермы. Мы покатаемся на поезде,
                сфотографируемся и насладимся красотой чайных плантаций.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Малый Пик Адама</Accordion.Header>
              <Accordion.Body>
                Поход на Малый пик Адама в Шри-Ланке — одно из самых эпических
                занятий в Элле. На вершину можно легко подняться, так как есть
                отмеченная тропа, ведущая на гору, с множеством указателей,
                указывающих путь.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>9 арочный мост</Accordion.Header>
              <Accordion.Body>
                Знаменитый мост с девятью арками делает его очень живописным
                местом, тем более что он расположен в густых джунглях и
                сельскохозяйственных угодьях.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header>Зиплайн </Accordion.Header>
              <Accordion.Body>
                Двухпроводная зип-линия протянулась более чем на полкилометра,
                скользит со скоростью 80 км/ч и предлагает вид с высоты птичьего
                полета на красивые холмы острова.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>Возвращение на юг (19:00)</Accordion.Header>
              <Accordion.Body>
                Ваш водитель отвезет вас в отель примерно в 19:00.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="p-1 row col-12 deco-text my-3">
          <h2 className="mt-2">Включено / Не включено</h2>
          <div className=" col-12 p-0">
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Транспорт в микроавтобусе</p>
            </div>
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Опытный рускоговорящий гид</p>
            </div>
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Входные билеты</p>
            </div>
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Закуски / напитки</p>
            </div>
          </div>
          <div className="col-12 p-0">
            <div className="col-12 d-flex">
              <FaTimes className="my-auto" />{" "}
              <p className="my-auto ml-2">Обед</p>
            </div>
            <div className="col-12 d-flex">
              <FaTimes className="my-auto" />{" "}
              <p className="my-auto ml-2">Зиплайн ($30)</p>
            </div>
            <div className="col-12 d-flex">
              <FaTimes className="my-auto" />{" "}
              <p className="my-auto ml-2">Личные расходы</p>
            </div>
          </div>
        </div>
        <div className="p-1 col-12 mx-auto deco-text my-3">
          <h2 className="mt-2">Стоимость</h2>
          <div className="col-12 p-0">
            <Pricetable />
          </div>
        </div>
        <div className="p-1 col-12 mx-auto deco-text my-3">
          <h2 className="mt-2">Галерея</h2>
          <div className="col-12 p-0">
            <Gallary8 />
          </div>
        </div>
        <div className="p-1 row col-12 deco-text my-3 mx-auto">
          <div className="p-0 col-12">
            <Booktour tour="Элла за один день - Yamu Ella" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
