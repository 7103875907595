import React from 'react'
import Gallery from "react-grid-gallery";
import Image1 from "../../Images/Tourists/i1.jpg"
import Image2 from "../../Images/Tourists/i2.JPG"
import Image3 from "../../Images/Tourists/i3.JPG"
import Image4 from "../../Images/Tourists/i4.JPG"
import Image5 from "../../Images/Tourists/i5.JPG"
import Image6 from "../../Images/Tourists/i6.JPG"
import Image7 from "../../Images/Tourists/i7.JPG"
import Image8 from "../../Images/Tourists/i8.JPG"
import Image9 from "../../Images/Tourists/i9.JPG"
import Image10 from "../../Images/Tourists/i10.JPG"
import Image11 from "../../Images/Tourists/i11.JPG"
import Image12 from "../../Images/Tourists/i12.JPG"
import Image13 from "../../Images/Tourists/i13.JPG"
import Image14 from "../../Images/Tourists/i14.JPG"
import Image15 from "../../Images/Tourists/i15.JPG"
import Image16 from "../../Images/Tourists/i16.JPG"
import Image17 from "../../Images/Tourists/i17.JPG"
import Image18 from "../../Images/Tourists/i18.JPG"
import Image19 from "../../Images/Tourists/i19.JPG"
import Image20 from "../../Images/Tourists/i20.JPG"
import Image21 from "../../Images/Tourists/i21.JPG"
import Image22 from "../../Images/Tourists/i22.JPG"
import Image23 from "../../Images/Tourists/i23.JPG"

const IMAGES = [
  {
    src: `${Image1}`,
    thumbnail: `${Image1}`,
    thumbnailWidth: 300,
    thumbnailHeight: 300,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: `${Image2}`,
    thumbnail: `${Image2}`,
    thumbnailWidth: 300,
    thumbnailHeight: 300,
    caption: "Boats (Jeshu John - designerspics.com)",
  },

  {
    src: `${Image3}`,
    thumbnail: `${Image3}`,
    thumbnailWidth: 300,
    thumbnailHeight: 300,
  },
  {
    src: `${Image4}`,
    thumbnail: `${Image4}`,
    thumbnailWidth: 300,
    thumbnailHeight: 300,
  },
  {
    src: `${Image5}`,
    thumbnail: `${Image5}`,
    thumbnailWidth: 350,
    thumbnailHeight: 212,
  },
  {
    src: `${Image6}`,
    thumbnail: `${Image6}`,
    thumbnailWidth: 350,
    thumbnailHeight: 212,
  },
  {
    src: `${Image7}`,
    thumbnail: `${Image7}`,
    thumbnailWidth: 210,
    thumbnailHeight: 212,
  },
  {
    src: `${Image8}`,
    thumbnail: `${Image8}`,
    thumbnailWidth: 225,
    thumbnailHeight: 212,
  },
  {
    src: `${Image9}`,
    thumbnail: `${Image9}`,
    thumbnailWidth: 350,
    thumbnailHeight: 212,
  },
  {
    src: `${Image10}`,
    thumbnail: `${Image10}`,
    thumbnailWidth: 212,
    thumbnailHeight: 212,
  },
  {
    src: `${Image11}`,
    thumbnail: `${Image11}`,
    thumbnailWidth: 330,
    thumbnailHeight: 212,
  },
  {
    src: `${Image12}`,
    thumbnail: `${Image12}`,
    thumbnailWidth: 350,
    thumbnailHeight: 212,
  },
  {
    src: `${Image13}`,
    thumbnail: `${Image13}`,
    thumbnailWidth: 350,
    thumbnailHeight: 212,
  },
  {
    src: `${Image14}`,
    thumbnail: `${Image14}`,
    thumbnailWidth: 200,
    thumbnailHeight: 212,
  },
  {
    src: `${Image15}`,
    thumbnail: `${Image15}`,
    thumbnailWidth: 190,
    thumbnailHeight: 212,
  },
  {
    src: `${Image16}`,
    thumbnail: `${Image16}`,
    thumbnailWidth: 170,
    thumbnailHeight: 212,
  },
  {
    src: `${Image17}`,
    thumbnail: `${Image17}`,
    thumbnailWidth: 300,
    thumbnailHeight: 212,
  },
  {
    src: `${Image18}`,
    thumbnail: `${Image18}`,
    thumbnailWidth: 190,
    thumbnailHeight: 212,
  },
  {
    src: `${Image19}`,
    thumbnail: `${Image19}`,
    thumbnailWidth: 330,
    thumbnailHeight: 212,
  },
  {
    src: `${Image20}`,
    thumbnail: `${Image20}`,
    thumbnailWidth: 180,
    thumbnailHeight: 212,
  },
  {
    src: `${Image21}`,
    thumbnail: `${Image21}`,
    thumbnailWidth: 300,
    thumbnailHeight: 212,
  },
  {
    src: `${Image22}`,
    thumbnail: `${Image22}`,
    thumbnailWidth: 300,
    thumbnailHeight: 212,
  },
  {
    src: `${Image23}`,
    thumbnail: `${Image23}`,
    thumbnailWidth: 300,
    thumbnailHeight: 212,
  },
];


const Photogrid = () => {
  return <Gallery images={IMAGES} />;
}

export default Photogrid