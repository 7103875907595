import React from "react";

const Abouthero = () => {
  return (
    <div className="container-fluid hero-background-about d-flex">
      <div className="container d-flex m-auto">
        <div className="m-auto d-flex">
          <h1 className="my-auto big-text  text-center">
           Компания <br /> YAMU TOURS
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Abouthero;
