import React, { useState } from "react";
import { Form, Col, Row, Alert } from "react-bootstrap";
import Footer from "../Maipage.js/Footer";
const axios = require("axios").default;

const Ordertransfer = () => {
  const [data, setData] = useState({
    name: "",
    start: "",
    end: "",
    flight: "",
    date: "",
    passengers: "",
    bags: "",
    cartype: "1",
    phone: "",
    tg: "",
    share: false,
  });

  const onChangeData = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onChangeCheck = (e) => {
    setData({ ...data, share: !data.share });
  };

  const TOKEN = "5382115648:AAHqm_H-_8AxxABLKceEQClxCU9UjcZO-EE";
  const CHAT_ID = "-1001620924289";
  const URI_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;
  const [alert, setAlert] = useState("d-none");

  const submit = (e) => {
    e.preventDefault();

    let message = `<b>Трансфер</b> \n`;
    message += `<b>Name:</b> ${data.name} \n`;
    message += `<b>Start:</b> ${data.start} \n`;
    message += `<b>End:</b> ${data.end} \n`;
    message += `<b>Flight:</b> ${data.flight} \n`;
    message += `<b>Date:</b> ${data.date} \n`;
    message += `<b>Passengers:</b> ${data.passengers} \n`;
    message += `<b>Bags:</b> ${data.bags} \n`;
    message += `<b>Cartype:</b> ${data.cartype} \n`;
    message += `<b>Phone:</b> ${data.phone} \n`;
    message += `<b>TG: </b> ${data.tg} \n`;
    message += `<b>Share:</b> ${data.share} \n`;

    axios
      .post(URI_API, {
        chat_id: CHAT_ID,
        parse_mode: "html",
        text: message,
      })
      .then((res) => {
        setData({
          name: "",
          start: "",
          end: "",
          flight: "",
          date: "",
          passengers: "",
          bags: "",
          cartype: "1",
          phone: "",
          tg: "",
          share: false,
        });

        setAlert("");
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {
        console.log(message);
      });
  };

  return (
    <div className="container-fluid p-0">
      <div className="row  br col-12 p-0 mx-auto">
        <div className="tour-hero1 d-flex br p-0">
          <div className="container d-flex mx-auto">
            <div className="row"></div>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-2 mb-3">
        <h3 className="text-center mt-5">Трансферы</h3>
        <p className="text-center">
          Заполните форму и мы свяжемся с вами в ближайшее время
        </p>
        <div className="row col-12 col-md-8 p-0 mx-auto mb-5">
          <Form>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Имя Фамилия</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Иван Иванов"
                  name="name"
                  value={data.name}
                  onChange={onChangeData}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Место отправления</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="аэропорт / отель"
                  name="start"
                  value={data.start}
                  onChange={onChangeData}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label> Место назначения</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="аэропорт / отель"
                  name="end"
                  value={data.end}
                  onChange={onChangeData}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Рэйс</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="SU288"
                  name="flight"
                  value={data.flight}
                  onChange={onChangeData}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label> Дата</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="21.04.2023"
                  name="date"
                  value={data.date}
                  onChange={onChangeData}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Количество человек</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="2"
                  name="passengers"
                  value={data.passengers}
                  onChange={onChangeData}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label> Количество багажа</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="2"
                  name="bags"
                  value={data.bags}
                  onChange={onChangeData}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label> Тип автомобиля</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="cartype"
                  value={data.cartype}
                  onChange={onChangeData}
                >
                  <option value="1">Компактная машина</option>
                  <option value="2">Машина комфорт-класса </option>
                </Form.Select>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>Номер Телефона (Воцап / Телеграм)</Form.Label>
                <Form.Control
                  placeholder="+7 929 986 53 82"
                  name="phone"
                  value={data.phone}
                  onChange={onChangeData}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>Телеграм Ник (если доступно) </Form.Label>
                <Form.Control
                  placeholder="@yamutours1"
                  name="tg"
                  value={data.tg}
                  onChange={onChangeData}
                />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" id="formGridCheckbox">
              <Form.Check
                type="checkbox"
                label="Готов(а) разделить трансфер "
                name="share"
                checked={data.share}
                onChange={onChangeCheck}
              />
            </Form.Group>

            <Alert key="success" variant="success" className={alert}>
              Успешно! Мы свяжемся с вами в ближайшее время.
            </Alert>

            <div
              className="col-12 p-0 row mx-auto mt-3 makebutton mb-5"
              onClick={submit}
            >
              {/* <Link to="/yamuyala" className="p-0 no-dec text-dark"> */}
              <div className="col-12  d-flex border-thin">
                <div className="col-12">
                  <h5 className="text-center my-1">Заказать</h5>
                </div>
              </div>
              {/* </Link> */}
            </div>

            {/* <Button variant="primary" type="submit" onClick={submit}>
              Отправить
            </Button> */}
          </Form>
        </div>
      </div>
        <Footer />
    </div>
  );
};

export default Ordertransfer;
