import React from 'react'
import Hero from './Hero'
import Details from './Details'

const KumanaSafari = () => {
  return (
      <div>
          <Hero />
          <Details/>
    </div>
  )
} 

export default KumanaSafari 