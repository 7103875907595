import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { FaCheck, FaTimes } from "react-icons/fa";
// import Photogrid from "./Photogrid";
import Pricetable from "./Pricetable"
import Booktour from "../../Minicomponents/Booktour";
// import Gallary8 from "./Gallary8";

const Details = () => {
  return (
    <div className="container d-flex p-0">
      <div className="col-12 mt-3 p-0 row mx-auto">
        <div className="col-12 col-md-3 row p-1 mx-auto">
          <div className="col-12  d-flex border-thin">
            <div className="col-12">
              <h5 className="text-center my-auto">Продолжительность</h5>
              <p className="text-center my-auto">8 часов</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 row p-1 mx-auto">
          <div className="col-12  d-flex border-thin">
            <div className="col-12">
              <h5 className="text-center my-auto">Стоимость</h5>
              <p className="text-center my-auto">от $50</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 row p-1 mx-auto ">
          <div className="col-12  d-flex border-thin">
            <div className="col-12">
              <h5 className="text-center my-auto">Групповый тур</h5>
              <p className="text-center my-auto">По Субботам</p>
            </div>
          </div>
        </div>
        <div className="p-1 my-3">
          <h2 className="mt-2 deco-text">Обзор</h2>
          <p className="deco-text">
            Однодневная экскурсия в Тринкомали - это захватывающее путешествие,
            включающее посещение храма Серувавила, пляжа Уппувели, индуистского
            храма Конешварам, крепости Фредрика и кормление оленей. Погрузитесь
            в богатую историю и культуру региона, наслаждаясь красотой пейзажей
            и духом святынь.
          </p>
        </div>

        <div className="p-1 my-3">
          <h2 className="mt-2 deco-text">Маршрут</h2>
          <Accordion defaultActiveKey="0" alwaysOpen className="deco-text">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="deco-text">
                Выезд 08:30
              </Accordion.Header>
              <Accordion.Body>
                Водитель прибудет в ваш отель или на вашу виллу, чтобы забрать
                вас. Если водитель не может найти ваш отель с помощью карт
                Google, он может позвонить вам.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Храм Серувавила</Accordion.Header>
              <Accordion.Body>
                Храм Серувавила - древнее святилище в Шри Ланке, привлекающее
                паломников своими святынями и духовной атмосферой, а также
                сохраненной архитектурой и историей.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Форт Федрик</Accordion.Header>
              <Accordion.Body>
                Форт Фредрик в Тринкомали – исторический памятник, уникальное
                свидетельство прошлого. Этот крепостной комплекс, построенный в
                17 веке, впечатляет своими укреплениями и архитектурой,
                предлагая путешественникам исторические рассказы и захватывающие
                виды на окружающие пейзажи.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Храм Конешваран</Accordion.Header>
              <Accordion.Body>
                Храм Конешварам в Тринкомали - древнейшее святилище, посвященное
                Шиве, важное духовное и культурное место. Его величественная
                архитектура и мистическая атмосфера привлекают паломников и
                туристов, оставляя незабываемые впечатления о духовном наследии
                Шри Ланки.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Кормление оленей</Accordion.Header>
              <Accordion.Body>
                Уникальный опыт, приносящий радость и умиротворение. В окружении
                природной красоты вы сможете поближе познакомиться с этими
                прекрасными созданиями и насладиться моментами гармонии с
                природой.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header>Пляж Уппувели</Accordion.Header>
              <Accordion.Body>
                Остановка на пляже Уппувели, где вы сможете отдохнуть,
                пообедать и поплавать в океане.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>Возвращение в отель (18:00)</Accordion.Header>
              <Accordion.Body>
                Ваш водитель отвезет вас в отель примерно в 18:00.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="p-1 row col-12 deco-text my-3">
          <h2 className="mt-2">Включено / Не включено</h2>
          <div className=" col-12 p-0">
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Транспорт</p>
            </div>
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Входные билеты</p>
            </div>
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Закуски / напитки</p>
            </div>  
          </div>
          <div className="col-12 p-0">
            <div className="col-12 d-flex">
              <FaTimes className="my-auto" />{" "}
              <p className="my-auto ml-2">Обед</p>
            </div>
            <div className="col-12 d-flex">
              <FaTimes className="my-auto" />{" "}
              <p className="my-auto ml-2">Личные расходы</p>
            </div>
          </div>
        </div>
        <div className="p-1 col-12 mx-auto deco-text my-3">
          <h2 className="mt-2">Стоимость</h2>
          <div className="col-12 p-0">
            <Pricetable />
          </div>
        </div>
        {/* <div className="p-1 col-12 mx-auto deco-text my-3">
          <h2 className="mt-2">Галерея</h2>
          <div className="col-12 p-0">
            <Gallary8 />
          </div>
        </div> */}
        <div className="p-1 row col-12 deco-text my-3 mx-auto">
          <div className="p-0 col-12">
            <Booktour tour="Однодневная экскурсия - Тринкомали" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
