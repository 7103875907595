import React from 'react'

const Topboxes = () => {
  return (
    <div className="container mx-auto my-5">
      <div className="row col-12 p-0 mx-auto">
        <div className="col-12 col-md-3 row p-1 mx-auto">
          <div className="col-12  d-flex border-thin">
            <div className="col-12">
              <h5 className="text-center my-auto">Продолжительность</h5>
              <p className="text-center my-auto">10 дней</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 row p-1 mx-auto">
          <div className="col-12  d-flex border-thin">
            <div className="col-12">
              <h5 className="text-center my-auto">Стоимость</h5>
              <p className="text-center my-auto">$899</p>
            </div> 
          </div> 
        </div>  
        <div className="col-12 col-md-3 row p-1 mx-auto ">
          <div className="col-12  d-flex border-thin">
            <div className="col-12">
              <h5 className="text-center my-auto">Тип тура</h5>
              <p className="text-center my-auto">Пляжный отдых + Экскурсии </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Topboxes