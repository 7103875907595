import React from 'react'

const Hero = () => {
  return (
    <div className="container-fluid hero-background-excursions d-flex">
      <div className="container d-flex m-auto">
        <div className="m-auto d-flex">
          <h1 className="my-auto big-text  text-center">
            Групповые и Индивидуальные
            <br /> Экскурсии <br /> на <br /> Шри Ланке
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Hero