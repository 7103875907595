import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

function BasicExample() {
  return (
    <Navbar bg="dark" variant="dark" sticky="top" expand="lg">
      {/* href="#home" */}
      <Container>
        <Navbar.Brand href="/" className="text-light">
          YAMU <span className="yellow-text">tours</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link href="excursions">Экскурсии</Nav.Link>
            <Nav.Link href="tourbuilder">Туры</Nav.Link>
            <Nav.Link href="transfers">Трансфер</Nav.Link>
            <Nav.Link href="aboutus">O нас</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BasicExample;
 