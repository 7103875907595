import React from 'react'
import { Carousel } from "react-bootstrap";

const NuwaraEliyaCarousel = () => {
  return (
    <Carousel className="col-12 p-0" controls={false} indicators={false} fade>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://res.cloudinary.com/thrillophilia/image/upload/c_fill,f_auto,fl_progressive.strip_profile,g_auto,q_auto/v1/filestore/1bf5hwd6n82hraudfg8x9ganfh2e_shutterstock_426154384.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://media.tacdn.com/media/attractions-splice-spp-674x446/0b/01/82/a2.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://media.tacdn.com/media/attractions-splice-spp-674x446/0b/39/a4/db.jpg"
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://www.attractionsinsrilanka.com/wp-content/uploads/2020/07/Nuwara-Eliya-Post-Office.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://res.cloudinary.com/thrillophilia/image/upload/c_fill,f_auto,fl_progressive.strip_profile,g_auto,q_auto/v1/filestore/7obmagocw09gzg8n4wkgpnh70xi4_shutterstock_158769194.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default NuwaraEliyaCarousel