import React from 'react'
import { Carousel} from "react-bootstrap";

const KandyCarousel = () => {
  return (
    <Carousel className="col-12 p-0" controls={false} indicators={false} fade>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://live.staticflickr.com/1900/44670839231_9492750bd4_c.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://images.squarespace-cdn.com/content/v1/53170656e4b04f773bf88c62/1410792765034-OXYD914S714E4NH3MQH0/pinnawala-elephant-orphanage-sri-lanka-invite-to-paradise-river-2.jpg?format=1000w"
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://live.staticflickr.com/795/41431966142_a40f78dfc9_c.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://nexttravelsrilanka.com/wp-content/uploads/2021/03/A-path-by-the-landscaped-lawns-with-colourful-fauna-at-the-Peradeniya-Botanical-Garden.jpg"
          alt="Forth slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://live.staticflickr.com/65535/51909292793_0bd1d9c84a_c.jpg"
          alt="Fifth slide"
        />
        
      </Carousel.Item>
    </Carousel>
  );
}

export default KandyCarousel