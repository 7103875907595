import React from 'react'
import {FaCheck} from "react-icons/fa"

const Hero = () => {
  return (
    <div className="container-fluid hero-background-tendays d-flex">
      <div className="container d-flex m-auto">
        <div className="m-auto row d-flex">
          <h1 className="my-auto big-text  text-center col-12">
            Шри Ланка <br /> Авторский Тур
          </h1>
          <p className="m-auto my-4 text-light mid-text text-center col-md-8">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio
            maxime tenetur eum, culpa voluptate magni sed laudantium eligendi
            fugiat natus pariatur atque qui facilis veniam?
          </p>
          <div className="row col-12 justify-content-center">
            <div className="col-6 d-flex text-light  text-center col-md-2 mx-auto justify-content-center">
              <FaCheck className="my-auto mr-3" />
              <p className="my-auto mid-text">Местный гид</p>
            </div>
            <div className="col-6 d-flex text-light  text-center col-md-2 mx-auto justify-content-center">
              <FaCheck className="my-auto mr-3" />
              <p className="my-auto mid-text">Индивидуальный тур</p>
            </div>
            <div className="col-6 d-flex text-light  text-center col-md-2 mx-auto justify-content-center">
              <FaCheck className="my-auto mr-3" />
              <p className="my-auto mid-text">4-дневная экскурсия </p>
            </div>
            <div className="col-6 d-flex text-light  text-center col-md-2 mx-auto justify-content-center">
              <FaCheck className="my-auto mr-3" />
              <p className="my-auto mid-text">6-дневный пляжный отдых </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero