import React, { useState } from "react";
import { Alert, Form } from "react-bootstrap";
import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa";

const axios = require("axios").default;

const Booktour = (props) => {
  const [data, setData] = useState({
    tour: props.tour,
    name: "",
    startcity: "",
    type: "Group",
    date: "",
    phone: "",
    tg: "",
    comments: "",
  });

  const onChangeData = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  // Add Tourists

  // Adults

  const [adult, setAdult] = useState(2);

  const reduceAdult = () => {
    setAdult(adult - 1);
  };

  const addAdult = () => {
    setAdult(adult + 1);
  };

  //Kids >2
  const [kids2, setKids2] = useState(0);

  const reduceKids2 = () => {
    setKids2(kids2 - 1);
  };

  const addKids2 = () => {
    setKids2(kids2 + 1);
  };

  //Kids >6
  const [kids6, setKids6] = useState(0);

  const reduceKids6 = () => {
    setKids6(kids6 - 1);
  };

  const addKids6 = () => {
    setKids6(kids6 + 1);
  };

  //Kids >12
  const [kids12, setKids12] = useState(0);

  const reduceKids12 = () => {
    setKids12(kids12 - 1);
  };

  const addKids12 = () => {
    setKids12(kids12 + 1);
  };

  const TOKEN = "5382115648:AAHqm_H-_8AxxABLKceEQClxCU9UjcZO-EE";
  const CHAT_ID = "-1001620924289";
  const URI_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;
  const [alert, setAlert] = useState("d-none");

  const submit = (e) => {
    e.preventDefault();

    let message = `<b>Трансфер</b> \n`;
    message += `<b>Tour:</b> ${data.tour} \n`;
    message += `<b>Name:</b> ${data.name} \n`;
    message += `<b>Start:</b> ${data.startcity} \n`;
    message += `<b>Type:</b> ${data.type} \n`;
    message += `<b>Date:</b> ${data.date} \n`;
    message += `<b>Adults:</b> ${adult} \n`;
    message += `<b>Kids2:</b> ${kids2} \n`;
    message += `<b>Kids6:</b> ${kids6} \n`;
    message += `<b>Kids12:</b> ${kids12} \n`;
    message += `<b>Phone:</b> ${data.phone} \n`;
    message += `<b>TG: </b> ${data.tg} \n`;
    message += `<b>comments:</b> ${data.comments} \n`;

    axios
      .post(URI_API, {
        chat_id: CHAT_ID,
        parse_mode: "html",
        text: message,
      })
      .then((res) => {
        setData({
          tour: props.tour,
          name: "",
          startcity: "",
          type: "Group",
          date: "",
          phone: "",
          tg: "",
          comments: "",
        });

        setAdult(2);
        setKids12(0);
        setKids2(0);
        setKids6(0);

        setAlert("");
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {
        console.log(message);
      });
  };

  return (
    <div className="container mt-5">
      <div className="row col-12 p-0 mx-auto">
        <div className="col-12 p-0 mx-auto d-flex my-2">
          <span className="yellow-span my-auto mr-1"></span>
          <h2 className="my-auto">Забранировать</h2>
        </div>
        <div className="col-12 d-flex p-0 mx-auto">
          <Form className="row col-12 mx-auto p-0">
            <div className="row col-12  p-0 mx-auto">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>{props.tour}</Form.Label>
              </Form.Group>
            </div>
            {/* <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Тип</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="type"
                  value={data.type}
                  onChange={onChangeData}
                >
                  <option value="group">Групповая экскурсия</option>
                  <option value="individual">Индивидуальная экскурсия </option>
                </Form.Select>
              </Form.Group>
            </div> */}
            <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Дата прилета</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="01.01.2023"
                  name="date"
                  value={data.date}
                  onChange={onChangeData}
                />
              </Form.Group>
            </div>
            {/* <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Отель / Адрес виллы</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Коломбо"
                  name="startcity"
                  value={data.startcity}
                  onChange={onChangeData}
                />
              </Form.Group>
            </div> */}

            <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Количество туристов</Form.Label>
                <div className="row col-12 p-0 mx-auto">
                  <div className="col-6">Взрослые (12+) :</div>
                  <div className="col-1">
                    <FaChevronCircleDown
                      onClick={reduceAdult}
                      className=" mr-3 my-auto makebutton"
                    />
                  </div>
                  <div className="col-1">{adult}</div>
                  <div className="col-1">
                    <FaChevronCircleUp
                      onClick={addAdult}
                      className="  my-auto makebutton"
                    />
                  </div>
                </div>
                <div className="row col-12 p-0 mx-auto">
                  <div className="col-6">Дети (до 2г) :</div>
                  <div className="col-1">
                    <FaChevronCircleDown
                      onClick={reduceKids2}
                      className=" mr-3 my-auto makebutton"
                    />
                  </div>
                  <div className="col-1">{kids2}</div>
                  <div className="col-1">
                    <FaChevronCircleUp
                      onClick={addKids2}
                      className="  my-auto makebutton"
                    />
                  </div>
                </div>
                <div className="row col-12 p-0 mx-auto">
                  <div className="col-6">Дети (до 6г) :</div>
                  <div className="col-1">
                    <FaChevronCircleDown
                      onClick={reduceKids6}
                      className=" mr-3 my-auto makebutton"
                    />
                  </div>
                  <div className="col-1">{kids6}</div>
                  <div className="col-1">
                    <FaChevronCircleUp
                      onClick={addKids6}
                      className="  my-auto makebutton"
                    />
                  </div>
                </div>
                <div className="row col-12 p-0 mx-auto">
                  <div className="col-6">Дети (до 12г) :</div>
                  <div className="col-1">
                    <FaChevronCircleDown
                      onClick={reduceKids12}
                      className=" mr-3 my-auto makebutton"
                    />
                  </div>
                  <div className="col-1">{kids12}</div>
                  <div className="col-1">
                    <FaChevronCircleUp
                      onClick={addKids12}
                      className="  my-auto makebutton"
                    />
                  </div>
                </div>
              </Form.Group>
            </div>
            <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Ваше имя</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Иван Ивонов"
                  name="name"
                  value={data.name}
                  onChange={onChangeData}
                />
              </Form.Group>
            </div>
            <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Номер телефона </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="+7 929 986 53 82"
                  name="phone"
                  value={data.phone}
                  onChange={onChangeData}
                />
              </Form.Group>
            </div>
            <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Телеграм Ник </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="@yamutours1"
                  name="tg"
                  value={data.tg}
                  onChange={onChangeData}
                />
              </Form.Group>
            </div>
            <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Коментарии / требования</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder=""
                  name="comments"
                  value={data.comments}
                  onChange={onChangeData}
                  aria-label="With textarea"
                />
              </Form.Group>
            </div>
            <div>
              <Alert key="success" variant="success" className={alert}>
                Успешно! Мы свяжемся с вами в ближайшее время.
              </Alert>
            </div>

            <div
              className="row col-12 col-md-4 makebutton mx-auto "
              onClick={submit}
            >
              <div className="col-12 mx-auto d-flex border-thin">
                <div className="col-12">
                  <h5 className="text-center my-1">Отправить</h5>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Booktour;
