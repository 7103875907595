import React from "react";
import { FaCarSide, FaHamburger, FaHeadset, FaHotel, FaTicketAlt, FaTrain, FaUserTie } from "react-icons/fa";
import {GiCarWheel,  GiSpermWhale} from "react-icons/gi"

const Overview = () => {
  return (
    <div className="container my-4">
      <div className="row p-0 mx-auto col-12">
        <div className="col-12 col-md-7 p-0 mx-auto ">
          <div className="col-12 p-0 mx-auto d-flex my-2">
            <span className="yellow-span my-auto mr-1"></span>
            <h2 className="my-auto">Шри-Ланка за 10 дней</h2>
          </div>
          <p>
            Шри-Ланка является одним из величайших туристических направлений
            всех времен! Прекрасный тропический остров посреди теплых вод
            Индийского океана, что может быть лучше? Наш 10-дневный тур —
            идеальный маршрут, за который вы пересекаете джунгли, горы, чайные
            плантации, а в итоге оказываетесь на райском побережье. Мы начнем с
            больших мегаполисов, а закончим расслабляющим отдыхом на прекрасных
            пляжах с кокосом в руке. Все, что вы только мечтали увидеть в этой
            прекрасной стране в нашем самом полном маршруте по Шри-Ланке.
          </p>
        </div>
        <div className="col-12 col-md-1 p-0 mx-auto"></div>
        <div className="col-12 col-md-4 p-0 mx-auto">
          <div className="col-12 p-0 mx-auto d-flex my-2">
            <span className="yellow-span my-auto mr-1"></span>
            <h2 className="my-auto">Что включено?</h2>
          </div>
          <div className="col-12 p-0 mx-auto d-flex my-2">
            <span className="my-auto mr-2">
              <FaHotel />
            </span>
            <p className="my-auto">4/5* проживние</p>
          </div>
          <div className="col-12 p-0 mx-auto d-flex my-2">
            <span className="my-auto mr-2">
              <FaUserTie />
            </span>
            <p className="my-auto">Русскоговорящий гид</p>
          </div>
          <div className="col-12 p-0 mx-auto d-flex my-2">
            <span className="my-auto mr-2">
              <FaCarSide />
            </span>
            <p className="my-auto">Транспорт (комфорт класс)</p>
          </div>
          <div className="col-12 p-0 mx-auto d-flex my-2">
            <span className="my-auto mr-2">
              <FaHamburger />
            </span>
            <p className="my-auto">Завтрак и ужин</p>
          </div>
          <div className="col-12 p-0 mx-auto d-flex my-2">
            <span className="my-auto mr-2">
              <FaTicketAlt />
            </span>
            <p className="my-auto">Все входные билеты</p>
          </div>
          {/* <div className="col-12 p-0 mx-auto d-flex my-2">
            <span className="my-auto mr-2">
              <GiCarWheel />
            </span>
            <p className="my-auto">Safari Jeep</p>
          </div> */}
          {/* <div className="col-12 p-0 mx-auto d-flex my-2">
            <span className="my-auto mr-2">
              <GiSpermWhale />
            </span>
            <p className="my-auto">Whale watching</p>
          </div> */}
          <div className="col-12 p-0 mx-auto d-flex my-2">
            <span className="my-auto mr-2">
              <FaTrain />
            </span>
            <p className="my-auto">Поездка на поезде</p>
          </div>
          <div className="col-12 p-0 mx-auto d-flex my-2">
            <span className="my-auto mr-2">
              <FaHeadset />
            </span>
            <p className="my-auto">
              Консультация по покупке авиабилетов и визы
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
