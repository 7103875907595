import React from "react";

const Gallary8 = () => {
  return (
    <div className="container p-0">
      {/* <div className="col-12 p-0 mx-auto d-flex my-2">
        <span className="yellow-span my-auto mr-1"></span>
        <h2 className="my-auto">Highlights</h2>
      </div> */}
      <div className="row col-12 p-0 mx-auto gallary8">
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img
            src={
              "https://live.staticflickr.com/5290/5250862990_dd03c78f30_k.jpg"
            }
            alt=""
            className="tenday-gallary8-img"
          />
        </div>
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img
            src={
              "https://live.staticflickr.com/5008/5250260401_04dd4a50b2_k.jpg"
            }
            alt=""
            className="tenday-gallary8-img"
          />
        </div>
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img
            src={
              "https://live.staticflickr.com/65535/52280232947_19f0168290_k.jpg"
            }
            alt=""
            className="tenday-gallary8-img"
          />
        </div>
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img
            src={
              "https://live.staticflickr.com/5005/5250863960_3f12a1793f_k.jpg"
            }
            alt=""
            className="tenday-gallary8-img"
          />
        </div>
      </div>
      <div className="row col-12 p-0 mx-auto gallary8">
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img
            src={
              "https://live.staticflickr.com/5047/5247813029_11e626a34f_k.jpg"
            }
            alt=""
            className="tenday-gallary8-img"
          />
        </div>
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img
            src={
              "https://live.staticflickr.com/5201/5248435094_304b76b092_k.jpg"
            }
            alt=""
            className="tenday-gallary8-img"
          />
        </div>
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img
            src={
              "https://live.staticflickr.com/5287/5248705601_29743c8692_k.jpg"
            }
            alt=""
            className="tenday-gallary8-img"
          />
        </div>
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img src={"https://live.staticflickr.com/5206/5247822815_c2dc8d8632_k.jpg"} alt="" className="tenday-gallary8-img" />
        </div>
      </div>
    </div>
  );
};

export default Gallary8;
