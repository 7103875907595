import React from "react";
import Table from "react-bootstrap/Table";

const Pricetable = () => {
  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Количество Туристов</th>
            <th>Стоимость</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Групповый тур <br /> (При наборе от 4-х чел по понедельникам)
            </td>
            <td>
              $50 / чел <br />
            </td>
          </tr>
          <tr>
            <td>
              Индивидуальный тур <br />
              (До 3-х взрослых)
            </td>
            <td>$95 / чел</td>
          </tr>
          <tr>
            <td>
              Индивидуальный тур <br />
              (4 - 5 взрослых )
            </td>
            <td>$75 / чел</td>
          </tr>
          <tr>
            <td>
              Индивидуальный тур <br />
              (От 5-и человек )
            </td>
            <td>$60 / чел</td>
          </tr>
          
        </tbody>
      </Table>
      <p>
        *В случае, если группа (4 чел) не наберется, организуем индивидуальную
        экскурсию по скидочной цене.
      </p>
    </div>
  );
};

export default Pricetable;
