import React from 'react'

const Hero = () => {
  return (
    <div className="container-fluid hero-background d-flex">
      <div className="row  br col-12 p-0 mx-auto">
        <div className="hero d-flex br p-0">
          <div className="container d-flex p-1">
            <div className="row">
              <div className="my-auto pl-0 col-12 pink-border pb-5">
                <h1 className="text-light  mt-2 ml-2">
                  Исследуй Шри-Ланку <br />с нашими турами
                </h1>
                <p className=" text-light p-0 mt-4 ml-2 col-md-8">
                  Живописные чайные плантации и водопады,
                  <br /> тропические пляжи, удивительная дикая природа,
                  <br />
                  исторические памятники и храмы -
                  <br />
                  Это Шри Ланка.
                  <br />
                  Присоединяйтесь к нашим турам и экскурсиям, <br />
                  чтобы получить уникальные и незабываемые впечатления от
                  путешествия.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero