import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { FaCheck, FaTimes } from "react-icons/fa";
// import Photogrid from "./Photogrid";
import Pricetable from "./Pricetable"
import Booktour from "../../Minicomponents/Booktour";
import Gallary8 from "./Gallary8";

const Details = () => {
  return (
    <div className="container d-flex p-0">
      <div className="col-12 mt-3 p-0 row mx-auto">
        <div className="col-12 col-md-3 row p-1 mx-auto">
          <div className="col-12  d-flex border-thin">
            <div className="col-12">
              <h5 className="text-center my-auto">Продолжительность</h5>
              <p className="text-center my-auto">13 часов</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 row p-1 mx-auto">
          <div className="col-12  d-flex border-thin">
            <div className="col-12">
              <h5 className="text-center my-auto">Стоимость</h5>
              <p className="text-center my-auto">от $65</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 row p-1 mx-auto ">
          <div className="col-12  d-flex border-thin">
            <div className="col-12">
              <h5 className="text-center my-auto">Групповый тур</h5>
              <p className="text-center my-auto">По Вторникам</p>
            </div>
          </div>
        </div>
        <div className="p-1 my-3">
          <h2 className="mt-2 deco-text">Обзор</h2>
          <p className="deco-text">
            Погрузитесь в захватывающую однодневную экскурсию в Сигирию.
            Посетите Сигирию или Пидурангалу, Золотой храм в Дамбулле,
            аюрведический сад, покормите слона и отправитесь на мини-экскурсию в
            деревню, где можно проехаться на телеге, катамаране, посетить
            фермерские поля, приготовить обед с местными жителями.
          </p>
        </div>

        <div className="p-1 my-3">
          <h2 className="mt-2 deco-text">Маршрут</h2>
          <Accordion defaultActiveKey="0" alwaysOpen className="deco-text">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="deco-text">
                Выезд 07:00
              </Accordion.Header>
              <Accordion.Body>
                Водитель прибудет в ваш отель или на вашу виллу, чтобы забрать
                вас. Если водитель не может найти ваш отель с помощью карт
                Google, он может позвонить вам.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Золотой храм Дамбулла</Accordion.Header>
              <Accordion.Body>
                Фото-остановка на 15 минут у буддийского храма в Дамбулле, где
                расположена золотая статуя.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Сад Аюрведа</Accordion.Header>
              <Accordion.Body>
                Посещайте Аюрведический сад на Шри-Ланке, где можно увидеть
                различные уникальные растения и получить различные лечебные
                процедуры и лекарства.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Кормление слона</Accordion.Header>
              <Accordion.Body>
                Мы посетим слоновий частный питомник, где вы сможете покормить
                слонов, искупаться со слонами и даже покататься на слонах, если
                будет время.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Мини экскурсия в деревне</Accordion.Header>
              <Accordion.Body>
                Насладитесь поездкой на телеге у пруда, затем отправьтесь на
                катамаране к другой части деревни. После прогулки по рисовым
                полям и овощным плантациям вы посетите дом местных жителей, где
                вас ждет мастер-класс по приготовлению традиционных блюд.
                Завершите день отведением вкусного ланкийского обеда или закусок
                и тук-туком вернитесь в отель.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header>Пидурангала или Сигирия </Accordion.Header>
              <Accordion.Body>
                Скала Сигирия – одно из самых знаковых мест Шри-Ланки. Вы можете
                подняться на скалу Сигирия или подняться на скалу Пидурангала,
                чтобы добраться до смотровой площадки и увидеть Сигирию.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>Возвращение в отель (20:00)</Accordion.Header>
              <Accordion.Body>
                Ваш водитель отвезет вас в отель примерно в 18:00.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="p-1 row col-12 deco-text my-3">
          <h2 className="mt-2">Включено / Не включено</h2>
          <div className=" col-12 p-0">
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Транспорт</p>
            </div>
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Опытный рускоговорящий гид</p>
            </div>
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Входные билеты</p>
            </div>
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Обед</p>
            </div>
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Закуски / напитки</p>
            </div>
          </div>
          <div className="col-12 p-0">
            <div className="col-12 d-flex">
              <FaTimes className="my-auto" />{" "}
              <p className="my-auto ml-2">Обед</p>
            </div>
            <div className="col-12 d-flex">
              <FaTimes className="my-auto" />{" "}
              <p className="my-auto ml-2">Входные билеты в Сигирию</p>
            </div>
            <div className="col-12 d-flex">
              <FaTimes className="my-auto" />{" "}
              <p className="my-auto ml-2">Личные расходы</p>
            </div>
          </div>
        </div>
        <div className="p-1 col-12 mx-auto deco-text my-3">
          <h2 className="mt-2">Стоимость</h2>
          <div className="col-12 p-0">
            <Pricetable />
          </div>
        </div>
        <div className="p-1 col-12 mx-auto deco-text my-3">
          <h2 className="mt-2">Галерея</h2>
          <div className="col-12 p-0">
            <Gallary8 />
          </div>
        </div>
        <div className="p-1 row col-12 deco-text my-3 mx-auto">
          <div className="p-0 col-12">
            <Booktour tour="Однодневная экскурсия - Сигирия" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
