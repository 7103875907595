import React from 'react'
import Hero from "./Hero";
import Details from "./Details";

const Kandyperahera = () => {
  return (
    <div>
      <Hero />
      <Details />
    </div>
  );
}

export default Kandyperahera