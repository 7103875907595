import React from 'react'
import { Carousel} from "react-bootstrap";

const KandyCarousel = () => {
  return (
    <Carousel className="col-12 p-0" controls={false} indicators={false} fade>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://images.thrillophilia.com/image/upload/s--CflGwxAE--/c_fill,g_auto,h_600,q_auto,w_975/f_auto,fl_strip_profile/v1/images/photos/000/110/166/original/1491646844_Sri_Lanka_-_029_-_Kandy_Temple_of_the_Tooth.jpg.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://images.squarespace-cdn.com/content/v1/53170656e4b04f773bf88c62/1410792765034-OXYD914S714E4NH3MQH0/pinnawala-elephant-orphanage-sri-lanka-invite-to-paradise-river-2.jpg?format=1000w"
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://keepcalmandwander.com/wp-content/uploads/2018/02/Kandy-Temple-of-the-Tooth-Sri-Lanka-9.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://nexttravelsrilanka.com/wp-content/uploads/2021/03/A-path-by-the-landscaped-lawns-with-colourful-fauna-at-the-Peradeniya-Botanical-Garden.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://meetinsrilanka.com/wp-content/uploads/2021/05/ambuluwawa-tower-sri-lanka-the-travelizer-featured.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default KandyCarousel