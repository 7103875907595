import React from 'react'
import Accordion from "react-bootstrap/Accordion";
import { FaCheck, FaTelegramPlane, FaWhatsapp } from "react-icons/fa";
import { AiFillPhone } from "react-icons/ai";
// import Card from "react-bootstrap/Card";
// import Carousel from "react-bootstrap/Carousel";

const Details = () => {
    return (
      <div className="container d-flex">
        <div className="col-12 my-3 p-1 row mx-auto">
          <div className="col-12 col-md-3 row p-1 mx-auto">
            <div className="col-12  d-flex border-thin">
              <div className="col-12">
                <h5 className="text-center my-auto">Продолжительность</h5>
                <p className="text-center my-auto">2 дня</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 row p-1 mx-auto">
            <div className="col-12  d-flex border-thin">
              <div className="col-12">
                <h5 className="text-center my-auto">Стоимость</h5>
                <p className="text-center my-auto">от $160</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 row p-1 mx-auto ">
            <div className="col-12  d-flex border-thin">
              <div className="col-12">
                <h5 className="text-center my-auto">Тип Тура</h5>
                <p className="text-center my-auto">Индивидуальный</p>
              </div>
            </div>
          </div>
          <div className="p-1 my-3">
            <h2 className="mt-2 deco-text">Обзор</h2>
            <p className="deco-text">
              2 day tour from Trincomalee will take you to the most iconic
              attractions in Sri Lanka. The tour starts early in the morning
              from Tricomalee and will take you to the lush green mountains of
              Sri Lanka. You will first visit the 2nd capital of Sri Lanka,
              Kandy. Walk around Royal Botanical Gardens and climb the
              Ambuluwawa Tower before heading towards the green mountains and
              white waterfalls. You can taste the best cup of tea in the whole
              world right from the tea factory enjoying the views of tea
              plantations. Spend the night in the most picturesque city in Sri
              Lanka with a BBQ dinner with a private chef. Day 2 starts with an
              excursion in Nuwara Eliya before heading towards the most popular
              Ella town. You will ride the world famous train for a part of the
              trip to Ella. Take those iconic photos in Ella and you'll be
              heading back to Trincomalee.
            </p>
          </div>

          <div className="p-1 my-3">
            <h2 className="mt-2 deco-text">День 1</h2>
            <Accordion defaultActiveKey="0" className="deco-text">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="deco-text">
                  Выезд из Тринкомали
                </Accordion.Header>
                <Accordion.Body>
                  Водитель прибудет в ваш отель или на вашу виллу, чтобы забрать
                  вас в 04:00. Если водитель не может найти ваш отель с помощью
                  карт Google, он может позвонить вам.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Королевский ботанический сад
                </Accordion.Header>
                <Accordion.Body>
                  Situated in Kandy’s suburb of Peradeniya, the spectacular
                  Peradeniya Botanical Garden dates to 1371. The gardens display
                  over 4 000 different species of plants, regarded amongst the
                  most superior in Asia. The highlight is the spectacular Orchid
                  collection and a mighty avenue of palm trees.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Башня Амбулувава</Accordion.Header>
                <Accordion.Body> 
                  Reaching the top of Ambuluwawa one can experience the most
                  amazing views which could sometimes be covered by the ongoing
                  mist. However, once the mist clears off the view can be scary
                  and freak out climbers, especially those who have a fear of
                  heights. Do note that hiking Ambuluwawa is not at all
                  dangerous or risky, but it would not be the ideal kind of
                  adventure for those with a fear of heights.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Водапад Рамбода</Accordion.Header>
                <Accordion.Body>
                  Situated in the Ramboda area of Nuwara Eliya district, Ramboda
                  falls is another popular waterfall in Sri Lanka. Though it is
                  not a well-known fact, Ramboda falls consists of 3 parts
                  called Ihala Ramboda Oya Ella (Upper Ramboda Oya Fall),
                  Ramboda Oya Meda Ella (Middle Fall), and Pahala Ramboda Oya
                  Ella ( Lower Ramboda Oya Falls)
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Чайные Плантаций / Фабрика</Accordion.Header>
                <Accordion.Body>
                  Сафари заканчивается около 18.30. Ваш водитель отвезет вас в
                  отель.
                </Accordion.Body>  
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="p-1 row col-12 deco-text my-3">
            <h2 className="mt-2">Включено / Не включено</h2>
            <div className=" col-12">
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Сафари джип</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Опытный водитель</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Входные билеты</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Закуски и вода</p>
              </div>
            </div>
            {/* <div className="col-6">
              <div className="col-12 d-flex">
                <FaTimes className="my-auto" />{" "}
                <p className="my-auto ml-2">Transport</p>
              </div>
              <div className="col-12 d-flex">
                <FaTimes className="my-auto" />{" "}
                <p className="my-auto ml-2">Transport</p>
              </div>
            </div> */}
          </div>
          {/* <div className="p-1 deco-text my-3">
            <h2 className="mt-2">Gallary</h2>
            <div className="row col-12 p-0 mx-auto">
              <div className="col-6 col-md-3 p-1 mx-auto">
                <Card className="card-width">
                  <Card.Img
                    variant="top"
                    src="https://images.unsplash.com/photo-1578517929034-db013fd86597?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                  />
                  <Card.Body>
                    <p className="text-center my-auto">Nuwara Eliya</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-6 col-md-3 p-1 mx-auto">
                <Card>
                  <Card.Img
                    variant="top"
                    src="https://images.unsplash.com/photo-1578517929034-db013fd86597?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                  />
                  <Card.Body>
                    <p className="text-center my-auto">Nuwara Eliya</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-6 col-md-3 p-1 mx-auto">
                <Card>
                  <Card.Img
                    variant="top"
                    src="https://images.unsplash.com/photo-1578517929034-db013fd86597?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                  />
                  <Card.Body>
                    <p className="text-center my-auto">Nuwara Eliya</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-6 col-md-3 p-1 mx-auto">
                <Card>
                  <Card.Img
                    variant="top"
                    src="https://images.unsplash.com/photo-1578517929034-db013fd86597?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                  />
                  <Card.Body>
                    <p className="text-center my-auto">Nuwara Eliya</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-6 col-md-3 p-1 mx-auto">
                <Card>
                  <Card.Img
                    variant="top"
                    src="https://images.unsplash.com/photo-1578517929034-db013fd86597?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                  />
                  <Card.Body>
                    <p className="text-center my-auto">Nuwara Eliya</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-6 col-md-3 p-1 mx-auto">
                <Card>
                  <Card.Img
                    variant="top"
                    src="https://images.unsplash.com/photo-1578517929034-db013fd86597?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                  />
                  <Card.Body>
                    <p className="text-center my-auto">Nuwara Eliya</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-6 col-md-3 p-1 mx-auto">
                <Card>
                  <Card.Img
                    variant="top"
                    src="https://images.unsplash.com/photo-1578517929034-db013fd86597?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                  />
                  <Card.Body>
                    <p className="text-center my-auto">Nuwara Eliya</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-6 col-md-3 p-1 mx-auto">
                <Card>
                  <Card.Img
                    variant="top"
                    src="https://images.unsplash.com/photo-1578517929034-db013fd86597?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                  />
                  <Card.Body>
                    <p className="text-center my-auto">Nuwara Eliya</p>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div> */}
          {/* <div className="p-1 deco-text my-3">
            <h2 className="mt-2">Reviews</h2>
            <div className="col-12 col-md-6 p-0 mx-auto">
              <Carousel variant="dark">
                <Carousel.Item>
                  <div className="review-box row col-12 py-5">
                    <div className="d-flex col-12">
                      <div className="client mx-auto"></div>
                    </div>
                    <div className="col-8 mx-auto">
                      {" "}
                      <p className="text-center">Michael Stevens</p>
                    </div>
                    <div className="col-8 mx-auto">
                      <p className="text-center">
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Eum aperiam quis dolore vitae ut tempore
                        necessitatibus facere. Rem fugiat ullam tempore
                        asperiores corporis dolor, cupiditate facere dicta
                        corrupti modi reiciendis.
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="review-box row col-12 py-5">
                    <div className="d-flex col-12">
                      <div className="client mx-auto"></div>
                    </div>
                    <div className="col-8 mx-auto">
                      {" "}
                      <p className="text-center">Michael Stevens</p>
                    </div>
                    <div className="col-8 mx-auto">
                      <p className="text-center">
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Eum aperiam quis dolore vitae ut tempore
                        necessitatibus facere. Rem fugiat ullam tempore
                        asperiores corporis dolor, cupiditate facere dicta
                        corrupti modi reiciendis.
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="review-box row col-12 py-5">
                    <div className="d-flex col-12">
                      <div className="client mx-auto"></div>
                    </div>
                    <div className="col-8 mx-auto">
                      {" "}
                      <p className="text-center">Michael Stevens</p>
                    </div>
                    <div className="col-8 mx-auto">
                      <p className="text-center">
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Eum aperiam quis dolore vitae ut tempore
                        necessitatibus facere. Rem fugiat ullam tempore
                        asperiores corporis dolor, cupiditate facere dicta
                        corrupti modi reiciendis.
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </div> */}
          <div className="p-1 deco-text my-3">
            <h2 className="mt-2">Свяжитесь с нами</h2>
            <div className="col-6 p-0">
              <div className="col-12 d-flex">
                <AiFillPhone className="my-auto" />{" "}
                <p className="my-auto ml-2">+7 929 986 53 82</p>
              </div>
              <div className="col-12 d-flex">
                <FaWhatsapp className="my-auto" />{" "}
                <p className="my-auto ml-2">+7 929 986 53 82</p>
              </div>
              <div className="col-12 d-flex">
                <FaTelegramPlane className="my-auto" />{" "}
                <p className="my-auto ml-2">@yamutours1</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Details