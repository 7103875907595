import React from 'react'
import Accordion from "react-bootstrap/Accordion";
import { FaCheck,  FaTimes,  } from "react-icons/fa";
import Photogrid from './Photogrid';
import Pricetable from './Pricetable';
import Booktour from '../../Minicomponents/Booktour';

const Details = () => {
    return (
      <div className="container d-flex p-0">
        <div className="col-12 my-3 p-1 row mx-auto">
          <div className="col-12 col-md-3 row p-1 mx-auto">
            <div className="col-12  d-flex border-thin">
              <div className="col-12">
                <h5 className="text-center my-auto">Продолжительность</h5>
                <p className="text-center my-auto">16 часов</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 row p-1 mx-auto">
            <div className="col-12  d-flex border-thin">
              <div className="col-12">
                <h5 className="text-center my-auto">Стоимость</h5>
                <p className="text-center my-auto">от $135</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 row p-1 mx-auto ">
            <div className="col-12  d-flex border-thin">
              <div className="col-12">
                <h5 className="text-center my-auto">Мини-групповые туры</h5>
                <p className="text-center my-auto">По понидельникам</p>
              </div>
            </div>
          </div>
          <div className="p-1 my-3">
            <h2 className="mt-2 deco-text">Обзор</h2>
            <p className="deco-text">
              Канди, вторая столица Шри-Ланки, окружена горными хребтами и
              чайными плантациями. Канди популярен среди путешественников,
              желающих провести день, посещая его колониальные здания и
              религиозные объекты. Мы начнем экскурсию с посещения слоновьего
              питомника Пиннавела, двигаясь к культовой башне Амбулувава. После
              обеда мы посетим лучший в Азии тропический ботанический сад, а
              затем посетим храм Зуба Будды.
            </p>
          </div>

          <div className="p-1 my-3">
            <h2 className="mt-2 deco-text">Маршрут</h2>
            <Accordion defaultActiveKey="0" alwaysOpen className="deco-text">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="deco-text">
                  Выезд 03:00
                </Accordion.Header>
                <Accordion.Body>
                  <h5 className="deco-text">Хиккадува : 04.00</h5>
                  <h5 className="deco-text">Унаватуна : 03.30</h5>
                  <h5 className="deco-text">Велигама / Мирисса : 03.00</h5>
                  Водитель прибудет в ваш отель или на вашу виллу, чтобы забрать
                  вас. Если водитель не может найти ваш отель с помощью карт
                  Google, он может позвонить вам. Вы должны оплатить полную
                  сумму при встрече с водителем.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="deco-text">
                  Час со слонами
                </Accordion.Header>
                <Accordion.Body>
                  Будем кормить слонов, мыть слонов и многое другое.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Аюрведический сад</Accordion.Header>
                <Accordion.Body>
                  Прогулка по саду, где выращивают специи и аюрведические травы.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Чайная фабрика</Accordion.Header>
                <Accordion.Body>
                  Мини-экскурсия на чайную фабрику для ознакомления с процессом
                  производства лучшего чая в мире.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Королевский Ботанический сад
                </Accordion.Header>
                <Accordion.Body>
                  Королевский ботанический сад - это самый большой и самый
                  красивый ботанический сад в Шри-Ланке. История его основания
                  начинается еще с королевских времен: первый Королевский парк
                  здесь появился в далеком 1747 году.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>Храм зуба Будды</Accordion.Header>
                <Accordion.Body>
                  Храм Зуба Будды в Канди на Шри-Ланке (Sri Dalada Maligawa) —
                  одно из самых почитаемых мест на Шри-Ланке, сердце буддизма
                  страны. Храм был построен для хранения великой реликвии — Зуба
                  Будды, который был спасен из погребального костра Будды.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>Башня Амбулувава </Accordion.Header>
                <Accordion.Body>
                  Башня Амбулувава была возведена в 2009 году, ее высота
                  составляет 48м. С вершины башни открывается панорамный вид на
                  360 градусов на окрестные города, долины и реки, вечнозеленые
                  леса.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>Возвращение в ваш отель</Accordion.Header>
                <Accordion.Body>
                  Экскурсия заканчивается около 22:00. Ваш водитель отвезет вас
                  в отель.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="p-1 row col-12 deco-text my-3">
            <h2 className="mt-2">Включено / Не включено</h2>
            <div className=" col-12 p-0">
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Транспорт в микроавтобусе</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Опытный рускоговорящий гид</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Входные билеты</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Закуски / напитки</p>
              </div>
            </div>
            <div className="col-12 p-0">
              <div className="col-12 d-flex">
                <FaTimes className="my-auto" />{" "}
                <p className="my-auto ml-2">Обеды</p>
              </div>
              <div className="col-12 d-flex">
                <FaTimes className="my-auto" />{" "}
                <p className="my-auto ml-2">Личные расходы</p>
              </div>
            </div>
          </div>

          <div className="p-1 deco-text my-3">
            <h2 className="mt-2">Gallary</h2>
            <Photogrid />
          </div>
          <div className="p-1 col-12 mx-auto deco-text my-3">
            <h2 className="mt-2">Стоимость</h2>
            <div className="col-12 p-0">
              <Pricetable />
            </div>
          </div>
          <div className="p-1 row col-12 deco-text my-3 mx-auto">
            <div className="p-0 col-12">
              <Booktour tour="Однодневная Экскурсия в город Канди - Yamu Kandy" />
            </div>
          </div>
        </div>
      </div>
    );
}

export default Details