import React from "react";
import Image1 from "../../../Images/Sigiriya/i1.JPG"
import Image2 from "../../../Images/Sigiriya/i2.JPG"
import Image3 from "../../../Images/Sigiriya/i3.JPG"
import Image4 from "../../../Images/Tourists/i30.JPG"
import Image5 from "../../../Images/Tourists/i38.JPG"
import Image6 from "../../../Images/Tourists/i41.jpeg"
import Image7 from "../../../Images/Tourists/i17.JPG"
// import Image8 from "../../../Images/Tourists/i35.jpg"  


const Gallary8 = () => {
  return (
    <div className="container p-0">
      {/* <div className="col-12 p-0 mx-auto d-flex my-2">
        <span className="yellow-span my-auto mr-1"></span>
        <h2 className="my-auto">Highlights</h2>
      </div> */}
      <div className="row col-12 p-0 mx-auto gallary8">
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img src={Image1} alt="" className="tenday-gallary8-img" />
        </div>
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img src={Image2} alt="" className="tenday-gallary8-img" />
        </div>
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img src={Image3} alt="" className="tenday-gallary8-img" />
        </div>
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img src={Image4} alt="" className="tenday-gallary8-img" />
        </div>
      </div>
      <div className="row col-12 p-0 mx-auto gallary8">
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img src={Image5} alt="" className="tenday-gallary8-img" />
        </div>
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img src={Image6} alt="" className="tenday-gallary8-img" />
        </div>
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img src={Image7} alt="" className="tenday-gallary8-img" />
        </div>
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img
            src={
              "https://live.staticflickr.com/7369/10796185073_aed3f6b065_c.jpg"
            }
            alt=""
            className="tenday-gallary8-img"
          />
        </div>
      </div>
    </div>
  );
};

export default Gallary8;
