import React from 'react'
import Abouthero from './Abouthero'
import {BsFillBookmarkCheckFill} from "react-icons/bs"
import Footer from '../../Maipage.js/Footer';
import { Card } from 'react-bootstrap';
import Dammika from "../../../Images/Guides/Dammika.JPG"
import Nandana from "../../../Images/Guides/Nandana.JPG"
import Akila from "../../../Images/Guides/Akila.jpg"
import Niran from "../../../Images/Guides/Niran.jpeg"
import Jayantha from "../../../Images/Guides/Jayantha.JPG"
import Chathura from "../../../Images/Guides/Chathura.jpeg"
import Rumesh from "../../../Images/Guides/Rumesh.jpeg"
import Sampath from "../../../Images/Guides/Sampath.jpeg"
import Ruwan from "../../../Images/Guides/Ruwan.jpeg"


const Aboutus = () => {
  return (
    <div>
      <Abouthero />
      <div className="container p-0 mt-4">
        <div className="container p-0">
          <div className="row  br col-12 p-0 mx-auto">
            <h3 className="text-center">Yamu tours в цифрах в 2022</h3>
            <div className="row col-12 p-0 mx-auto justify-content-center mt-3">
              <div className="col-12 col-md-2 my-2">
                <h4 className="text-center">200+</h4>
                <p className="text-center">Проверенных отелей</p>
              </div>
              <div className="col-12 col-md-2 my-2">
                <h4 className="text-center">30+</h4>
                <p className="text-center">Готовых маршрутов</p>
              </div>
              <div className="col-12 col-md-2 my-2">
                <h4 className="text-center">3000+</h4>
                <p className="text-center">Доволных туристов</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container p-0 mx-auto mt-4">
        <div className="row col-12 p-0 mx-auto">
          <div className="col-12  p-0 mx-auto p-3 row">
            <h2 className="col-12 text-center">
              Исследуем Шри-Ланку с Yamu tours
            </h2>
            <p className="col-12 col-md-8 mx-auto text-center">
              Мы в «Yamu Tours» организуем групповые и индивидуальные туры для
              туристов, посещающих Шри-Ланку, чтобы сделать их отдых
              незабываемым. Наша команда состоит из русскоговорящих гидов с
              более чем 10-летним опытом работы в туризме и опытных водителей,
              которые готовы обслужить вас.
            </p>
            <div className="col-12 d-flex">
              <div className="mx-auto">
                <span className="col-12 d-flex mx-auto">
                  <BsFillBookmarkCheckFill className="my-auto" />{" "}
                  <p className=" ml-2 my-auto">Лицензированные гиды</p>
                </span>
                <span className=" col-12 d-flex">
                  <BsFillBookmarkCheckFill className="my-auto" />{" "}
                  <p className="ml-2 my-auto">Опытные водители</p>
                </span>
                <span className=" col-12 d-flex">
                  <BsFillBookmarkCheckFill className="my-auto" />{" "}
                  <p className="ml-2 my-auto">Проверенные отели</p>
                </span>
                <span className=" col-12 d-flex">
                  <BsFillBookmarkCheckFill className="my-auto" />{" "}
                  <p className="ml-2 my-auto">Транспорт Комфорт класса</p>
                </span>
              </div>
            </div>
          </div>
          {/* <div className="col-12 col-md-6 p-0 mx-auto p-3">
            <div className="col-12 p-0 mx-auto background-about"></div>
          </div> */}
        </div>
      </div>

      <div className="container mt-4 p-0">
        <h2 className="col-12 my-3 deco-text text-center font-800">
          Наша Команда
        </h2>
        <div className="row col-12 p-0 mx-auto my-5">
          <div className="col-12 col-md-4 mx-auto px-5 my-2">
            <div className="col-12 mx-auto team-box">
              <div className="d-flex mx-auto">
                <img
                  src={Dammika}
                  alt=""
                  className="team-image-box mx-auto mt-5"
                />
              </div>
              <div className="row col-12 mx-auto mt-3">
                <h5 className="col-12 text-center mx-auto">Даммика</h5>
                <p className="col-12 mx-auto text-center">
                  Дамикка — русскоговорящий гид, опыт работы в туризме более 15
                  лет. Он лицензированный гид мин-туризма и более 4 лет прожил в
                  русскоязычных странах.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 mx-auto px-5 my-2">
            <div className="col-12 team-box">
              <div className="d-flex">
                <img
                  src={Nandana}
                  alt=""
                  className="team-image-box mx-auto mt-5"
                />
              </div>
              <div className="row col-12 mx-auto mt-3">
                <h5 className="col-12 text-center mx-auto">Саман</h5>
                <p className="col-12 mx-auto text-center">
                  Нандана работает в сфере туризма более 15 лет. Саман свободно
                  говорит по-русски, прожил в русскоязычных странах более 8 лет
                  и имеет лицензию Министерства туризма на право проведения
                  экскурсий.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4 mx-auto px-5 my-2">
            <div className="col-12 team-box">
              <div className="d-flex">
                <img
                  src={Niran}
                  alt=""
                  className="team-image-box mx-auto mt-5"
                />
              </div>
              <div className="row col-12 mx-auto mt-3">
                <h5 className="col-12 text-center mx-auto">Ниран</h5>
                <p className="col-12 mx-auto text-center">
                  Ниран работает русскоязычным гидом с 2009-ого года. Он имеет
                  лицензию Министерства туризма в качестве гида и выучил русский
                  язык как на Шри-Ланке, так и в России.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4 mx-auto px-5 my-2">
            <div className="col-12 team-box">
              <div className="d-flex">
                <img
                  src={Akila}
                  alt=""
                  className="team-image-box mx-auto mt-5"
                />
              </div>
              <div className="row col-12 mx-auto mt-3">
                <h5 className="col-12 text-center mx-auto">Акила</h5>
                <p className="col-12 mx-auto text-center">
                  Акила обрабатывает все заявки на туры от туристов и помогает
                  предоставить туристам больше информации о турах и узнать
                  больше о Шри-Ланке.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4 mx-auto px-5 my-2">
            <div className="col-12 team-box">
              <div className="d-flex">
                <img
                  src={Jayantha}
                  alt=""
                  className="team-image-box mx-auto mt-5"
                />
              </div>
              <div className="row col-12 mx-auto mt-3">
                <h5 className="col-12 text-center mx-auto">Джаянта</h5>
                <p className="col-12 mx-auto text-center">
                  Джаянта — англоговорящий гид-водитель, работающий в сфере
                  туризма более 15 лет. Он работает с англоговорящими русскими
                  туристами и туристами из Европы.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4 mx-auto px-5 my-2">
            <div className="col-12 team-box">
              <div className="d-flex">
                <img
                  src={Rumesh}
                  alt=""
                  className="team-image-box mx-auto mt-5"
                />
              </div>
              <div className="row col-12 mx-auto mt-3">
                <h5 className="col-12 text-center mx-auto">Румеш</h5>
                <p className="col-12 mx-auto text-center">
                  Румеш — англоговорящий гид-шофер, который возит туристов на
                  1-3-дневные мини-экскурсии по центральной части острова.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4 mx-auto px-5 my-2">
            <div className="col-12 team-box">
              <div className="d-flex">
                <img
                  src={Chathura}
                  alt=""
                  className="team-image-box mx-auto mt-5"
                />
              </div>
              <div className="row col-12 mx-auto mt-3">
                <h5 className="col-12 text-center mx-auto">Чатура</h5>
                <p className="col-12 mx-auto text-center">
                  Чатура — англоговорящий водитель, который занимается
                  трансферами и экскурсиями. Работает в сфере туризма более 6
                  лет.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4 mx-auto px-5 my-2">
            <div className="col-12 team-box">
              <div className="d-flex">
                <img
                  src={Sampath}
                  alt=""
                  className="team-image-box mx-auto mt-5"
                />
              </div>
              <div className="row col-12 mx-auto mt-3">
                <h5 className="col-12 text-center mx-auto">Сампат</h5>
                <p className="col-12 mx-auto text-center">
                  Sampath — англоговорящий гид-водитель, работает в туризме с
                  2012 года.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4 mx-auto px-5 my-2">
            <div className="col-12 team-box">
              <div className="d-flex">
                <img
                  src={Ruwan}
                  alt=""
                  className="team-image-box mx-auto mt-5"
                />
              </div>
              <div className="row col-12 mx-auto mt-3">
                <h5 className="col-12 text-center mx-auto">Руван</h5>
                <p className="col-12 mx-auto text-center">
                  Руван — англоговорящий гид-водитель, работающий в сфере
                  туризма с 2015 года.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <Gallary/> */}
      </div>
      <div className="container p-0 mx-auto mt-4">
        <div className="row col-12 p-0 mx-auto">
          <div className="col-12  p-0 mx-auto p-3 row">
            <h2 className="col-12 text-center">Информация о Компании</h2>
            <p className="col-12 col-md-8 mx-auto text-center">
              Название комании : YAMUtours by Belka Holidays (Private) Limited{" "}
              <br />
              Номер регистрации : PV00289746 <br />
              Адрес регистрации : N0 163/13A, Kalugalla Road, Kegalle
            </p>
          </div>
          {/* <div className="col-12 col-md-6 p-0 mx-auto p-3">
            <div className="col-12 p-0 mx-auto background-about"></div>
          </div> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Aboutus 