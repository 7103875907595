import React from 'react'

const Hero = () => {
  return (
    <div className="container-fluid hero-background-trinco d-flex">
      <div className="container d-flex m-auto">
        <div className="m-auto d-flex"> 
          <h1 className="my-auto big-text text-center">
            Двухдневный <br />
            индивидуальный <br /> Тур <br /> из <br /> Тринкомали 
          </h1> 
        </div>
      </div> 
    </div>
  );
}

export default Hero 