import React from 'react'
import { Carousel } from 'react-bootstrap';


const Reviews = () => {
  return (
    <div className="container my-5">
      <div className="col-12 p-0 mx-auto d-flex my-2">
        <span className="yellow-span my-auto mr-1"></span>
        <h2 className="my-auto">Отзывы</h2>
      </div>
      <div className="col-12 col-md-6 p-0 mx-auto">
        <Carousel variant="dark" className="p-0 min-height-review">
          <Carousel.Item>
            <div className="review-box row col-12 py-5">
              <div className="d-flex col-12">
                <div className="client-anna mx-auto"></div>
              </div>
              <div className="col-8 mx-auto mt-2">
                {" "}
                <p className="text-center">Анна </p>
              </div>
              <div className="col-9 mx-auto">
                <p className="text-center">
                  Спасибо большое вам и вашему чудесному гиду Даммике. Очень
                  интересная экскурсия, время пролетело незаметно. Желаем вашей
                  компании дальнейшего процветания и побольше туристов! <br />
                  25.07.2022
                </p>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="review-box row col-12 py-5 ">
              <div className="d-flex col-12">
                <div className="client-nikita mx-auto"></div>
              </div>
              <div className="col-8 mx-auto mt-2">
                {" "}
                <p className="text-center">Никита</p>
              </div>
              <div className="col-9 mx-auto">
                <p className="text-center">
                  Спасибо большое. Тур нам очень понравился, гид чудесный - ему
                  отдельное спасибо! Очень общительный, много рассказал
                  интересных фактов о парке Яла и о Шри-Ланке в целом!
                  <br />
                  02.08.2022
                </p>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="review-box row col-12 py-5">
              <div className="d-flex col-12">
                <div className="client-ivan mx-auto"></div>
              </div>
              <div className="col-8 mx-auto mt-2">
                {" "}
                <p className="text-center">Иван</p>
              </div>
              <div className="col-8 mx-auto">
                <p className="text-center">
                  Все прошло отлично, нам очень понравилось, все удалось, кроме
                  купания в водопаде, т.к. был дождь. Гид помог даже с
                  организацией спонтанного спуска на зиплайне
                  <br />
                  02.05.2022
                </p>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="review-box row col-12 py-5">
              <div className="d-flex col-12">
                <div className="client-vladmir mx-auto"></div>
              </div>
              <div className="col-8 mx-auto mt-2">
                {" "}
                <p className="text-center">Владимир</p>
              </div>
              <div className="col-8 mx-auto">
                <p className="text-center">
                  Все прошло отлично, нам понравилось. Гид и водитель настоящие
                  профессионалы, во всем нам помогали и рассказывали много
                  интересного. Всем рекомендую.
                  <br />
                  07.05.2022
                </p>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
        <a
          // href= "https://t.me/yamureviews"

          // target="_blank"
          href="https://t.me/yamureviews"
          className="p-0 no-dec text-dark"
        >
          <div className="px-3">
            <div className="col-12  d-flex border-thin">
              <div className="col-12">
                <h5 className="text-center my-2">Все отзывы</h5>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

export default Reviews