import React from 'react'

const Hero = () => {
  return (
    <div className="container-fluid hero-background-perahera d-flex">
      <div className="container d-flex m-auto">
        <div className="m-auto d-flex">
          <h1 className="my-auto big-text  text-center">
            Фестивал <br /> Перахара <br /> + <br /> Экскурсия <br />
            в <br /> Кэнди <br />  
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Hero