import React from "react";

const Gallary8 = () => {
  return (
    <div className="container p-0">
      {/* <div className="col-12 p-0 mx-auto d-flex my-2">
        <span className="yellow-span my-auto mr-1"></span>
        <h2 className="my-auto">Highlights</h2>
      </div> */}
      <div className="row col-12 p-0 mx-auto gallary8">
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img
            src={
              "https://live.staticflickr.com/65535/49745759472_c9d463bd37_b.jpg"
            }
            alt=""
            className="tenday-gallary8-img"
          />
        </div>
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img
            src={
              "https://live.staticflickr.com/7876/46576793502_ee45b8e8fb_b.jpg"
            }
            alt=""
            className="tenday-gallary8-img"
          />
        </div>
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img
            src={
              "https://live.staticflickr.com/1522/26266499975_19b4b1c0d6_b.jpg"
            }
            alt=""
            className="tenday-gallary8-img"
          />
        </div>
        <div className="col-6 col-md-3 p-0 mx-auto">
          <img
            src={
              "https://live.staticflickr.com/65535/47317501272_f772d0e337_h.jpg"
            }
            alt=""
            className="tenday-gallary8-img"
          />
        </div>
      </div>
      
    </div>
  );
};

export default Gallary8;
