import React from 'react'
import { Carousel } from "react-bootstrap";

const EllaCarousel = () => {
  return (
    <Carousel className="col-12 p-0" controls={false} indicators={false} fade>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://youtravel.me/upload/tours/31541/media/6de/6o6b0xxrv0iwmsbnrvpz1v3t2hvn9vjy.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0c/3a/63/e6/viewpoint-photography.jpg?w=1200&h=-1&s=1"
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://www.ceylonexpeditions.com/medias/media/big/687/depositphotos-181997958-xl-2015-1.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://bmkltsly13vb.compat.objectstorage.ap-mumbai-1.oraclecloud.com/cdn.dailymirror.lk/assets/uploads/image_dd341e182b.jpg"
          alt="Forth slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://d1ynolcus8dvgv.cloudfront.net/2019/04/flying-rawana-header-image-970%C3%97600.jpg"
          alt="Fifth slide"
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default EllaCarousel