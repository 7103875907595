import React from "react";
import Table from "react-bootstrap/Table";

const Pricetable = () => {
  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Количество Туристов</th>
            <th>Стоимость</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Групповый тур <br /> (При наборе от 4-х чел по понидельникам)
            </td>
            <td>
              $135 / чел <br />
            </td>
          </tr>
          <tr>
            <td>
              Индивидуальный тур <br />
              (До 3-х взрослых)
            </td>
            <td>$190 / чел</td>
          </tr>
          <tr>
            <td>
              Индивидуальный тур <br />
              (4 - 5 взрослых )
            </td>
            <td>$150 / чел</td>
          </tr>
          <tr>
            <td>
              Индивидуальный тур <br />
              (От 5-и человек )
            </td>
            <td>$135 / чел</td>
          </tr>
        </tbody>
      </Table>
      <p>
      </p>
    </div>
  );
};

export default Pricetable;
