import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { FaCheck, FaTimes } from "react-icons/fa";
import Pricetable from "./Pricetable"
import Booktour from "../../Minicomponents/Booktour";
import Gallary8 from "./Gallary8";

const Details = () => {
  return (
    <div className="container d-flex p-0">
      <div className="col-12 mt-3 p-0 row mx-auto">
        <div className="col-12 col-md-3 row p-1 mx-auto">
          <div className="col-12  d-flex border-thin">
            <div className="col-12">
              <h5 className="text-center my-auto">Продолжительность</h5>
              <p className="text-center my-auto">3 часа</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 row p-1 mx-auto">
          <div className="col-12  d-flex border-thin">
            <div className="col-12">
              <h5 className="text-center my-auto">Стоимость</h5>
              <p className="text-center my-auto">$130</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 row p-1 mx-auto ">
          <div className="col-12  d-flex border-thin">
            <div className="col-12">
              <h5 className="text-center my-auto">Групповый тур</h5>
              <p className="text-center my-auto">По субботам</p>
            </div>
          </div>
        </div>
        <div className="p-1 my-3">
          <h2 className="mt-2 deco-text">Обзор</h2>
          <p className="deco-text">
            Отдых на Шри-Ланке не обходится без наблюдения заката в Индийском
            океане. Каждую субботу пары, семьи и друзья могут насладиться этим
            прекрасным частным круизом на закате, наслаждаясь бокалом
            шампанского. Круиз включает легкий перекус, прохладительные напитки
            и водные виды спорта.
          </p>
        </div>

        <div className="p-1 my-3">
          <h2 className="mt-2 deco-text">Программа</h2>
          <Accordion defaultActiveKey="0" alwaysOpen className="deco-text">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="deco-text">
                Подготовка
              </Accordion.Header>
              <Accordion.Body>
                Если вы хотите присоединиться, вы должны зарегистрироваться
                минимум за 24 часа до начала прогулки на яхте. Вам необходимо
                заполнить форму ниже и отправить нам фотографии главной страницы
                вашего паспорта. Это необходимо для получения разрешения
                береговой охраны на посадку на яхту.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Возьмем с собой</Accordion.Header>
              <Accordion.Body>
                На яхте предоставляются закуски и вода. Алкоголь не
                предоставляется. Так что, при желании, вы можете взять с собой
                шампанское или любой другой напиток. На яхте есть бокалы для
                шампанского и лед. Если вы хотите поплавать или покататься на
                байдарках, возьмите с собой купальники.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Оплата</Accordion.Header>
              <Accordion.Body>
                Вы должны оплатить за 24 часа до начала тура. Оплата может быть
                произведена банковским переводом. К оплате принимаются рубли,
                доллары, рупии и криптовалюты.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Трансфер</Accordion.Header>
              <Accordion.Body>
                Вы можете встретить нашего представителя на главном пляже
                Пасикуды или мы можем организовать трансфер из вашего отеля за
                дополнительную плату.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Прогулка на Яхте</Accordion.Header>
              <Accordion.Body>
                Прогулка на яхте начинается в 15.00 и продолжается до 18.00. Во
                время поездки у вас есть возможность заняться сап-сёрфингом,
                поплавать, порыбачить и даже заняться сноркелингом, если вода
                чистая.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="p-1 row col-12 deco-text my-3">
          <h2 className="mt-2">Включено / Не включено</h2>
          <div className=" col-12 p-0">
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Люкс яхта</p>
            </div>
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Легкий перекус</p>
            </div>
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Бокалы для шампанского, лед</p>
            </div>
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Сап-серфинг</p>
            </div>
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Рыбалка</p>
            </div>
          </div>
          <div className="col-12 p-0">
            <div className="col-12 d-flex">
              <FaTimes className="my-auto " />{" "}
              <p className="my-auto ml-2">Трансфер</p>
            </div>
            <div className="col-12 d-flex">
              <FaTimes className="my-auto" />{" "}
              <p className="my-auto ml-2">Алкоголь</p>
            </div>
            <div className="col-12 d-flex">
              <FaTimes className="my-auto" />{" "}
              <p className="my-auto ml-2">Другие напитки / еда</p>
            </div>
          </div>
        </div>
        <div className="p-1 deco-text my-3">
          <h2 className="mt-2">Фотографии</h2>
          <Gallary8 />
        </div>
        <div className="p-1 col-12 mx-auto deco-text my-3">
          <h2 className="mt-2">Стоимость</h2>
          <div className="col-12 p-0">
            <Pricetable />
          </div>
        </div>
        <div className="p-1 row col-12 deco-text my-3 mx-auto">
          <div className="p-0 col-12">
            <Booktour tour="Закат на Яхте" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
