import React from "react";
import {
  FaCarSide,
  FaHamburger,
  FaHeadset,
  FaGasPump, FaHotel,
  FaTicketAlt,
  FaTrain,
  FaUserTie,
} from "react-icons/fa";
import {GiCarWheel,  GiSpermWhale} from "react-icons/gi"

const Overview = () => {
  return (
    <div className="container my-4">
      <div className="row p-0 mx-auto col-12">
        <div className="col-12 col-md-7 p-0 mx-auto ">
          <div className="col-12 p-0 mx-auto d-flex my-2">
            <span className="yellow-span my-auto mr-1"></span>
            <h2 className="my-auto">Шри-Ланка с YAMUtours</h2>
          </div>
          <p>
            YAMUtours специализируется на аренде автомобилей и водителей на
            Шри-Ланке и предлагает лучших гидов-водителей на острове, которые
            предложат вам по-настоящему локальные впечатления о Шри-Ланке,
            безопасно довезут вас даже в самые отдаленные места во время вашего
            отпуска на Шри-Ланке. У нас есть опытная команда, которая также
            может помочь бесплатно спланировать маршрут для вашего отпуска, наша
            команда может помочь вам спланировать ваш отпуск, посоветовать
            лучшие места для посещения и предложить несколько интересных
            вариантов отелей. Доверьтесь нам и исследуйте настоящую Шри-Ланку,
            где вы получите подлинный опыт, будете экологичны, почувствуете
            единение с дикой природой и местными жителями, без вреда для
            окружающей среды.
          </p>
        </div>
        <div className="col-12 col-md-1 p-0 mx-auto"></div>
        <div className="col-12 col-md-4 p-0 mx-auto">
          <div className="col-12 p-0 mx-auto d-flex my-2">
            <span className="yellow-span my-auto mr-1"></span>
            <h2 className="my-auto">Что включено?</h2>
          </div>
          <div className="col-12 p-0 mx-auto d-flex my-2">
            <span className="my-auto mr-2">
              <FaCarSide />
            </span>
            <p className="my-auto">Автомбиль</p>
          </div>
          <div className="col-12 p-0 mx-auto d-flex my-2">
            <span className="my-auto mr-2">
              <FaUserTie />
            </span>
            <p className="my-auto">Опытный англоговорящий водитель</p>
          </div>
          <div className="col-12 p-0 mx-auto d-flex my-2">
            <span className="my-auto mr-2">
              <FaGasPump />
            </span>
            <p className="my-auto">Топливо</p>
          </div>
          <div className="col-12 p-0 mx-auto d-flex my-2">
            <span className="my-auto mr-2">
              <FaTicketAlt />
            </span>
            <p className="my-auto">Плата за парковку</p>
          </div>
          <div className="col-12 p-0 mx-auto d-flex my-2">
            <span className="my-auto mr-2">
              <FaHamburger />
            </span>
            <p className="my-auto">Проживание и питание водителя</p>
          </div>
          {/* <div className="col-12 p-0 mx-auto d-flex my-2">
            <span className="my-auto mr-2">
              <GiCarWheel />
            </span>
            <p className="my-auto">Safari Jeep</p>
          </div> */}
          {/* <div className="col-12 p-0 mx-auto d-flex my-2">
            <span className="my-auto mr-2">
              <GiSpermWhale />
            </span>
            <p className="my-auto">Whale watching</p>
          </div> */}
          {/* <div className="col-12 p-0 mx-auto d-flex my-2">
            <span className="my-auto mr-2">
              <FaTrain />
            </span>
            <p className="my-auto">Поездка на поезде</p>
          </div> */}
          <div className="col-12 p-0 mx-auto d-flex my-2">
            <span className="my-auto mr-2">
              <FaHeadset />
            </span>
            <p className="my-auto">Консультация на русском языке</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
