import React from 'react'
import Table from "react-bootstrap/Table";

const Pricetable = () => {
  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Количество Туристов</th>
            <th>Стоимость</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Групповые экскурсии <br /> (По вторникам, четвергам и субботам)
            </td>
            <td>
              $130 / чел (Пидурангала) <br />
              $160 / чел (Сигирия)
              <br />
              (при одноместном размещении доплата - $30)
            </td>
          </tr>
          <tr>
            <td>
              Индивидуальный тур <br />
              (До 3-х взрослых)
            </td>
            <td>
              $330 / чел (Пидурангала) <br />
              $360 / чел (Сигирия)
              <br />
            </td>
          </tr>
          <tr>
            <td>
              Индивидуальный тур <br />
              (4 - 5 взрослых )
            </td>
            <td>
              $250 / чел (Пидурангала) <br />
              $280 / чел (Сигирия)
            </td>
          </tr>
          <tr>
            <td>
              Индивидуальный тур <br />
              (От 6-и взрослых )
            </td>
            <td>от $130 / чел</td>
          </tr>
        </tbody>
      </Table>
      <p>Скидки для детей : Бесплатно для детей до 6 лет и скидка 50% для детей до 12 лет.</p>
    </div>
  );
}

export default Pricetable