import React from "react";
import Table from "react-bootstrap/Table";

const Pricetable = () => {
  return (
    <div className="d-flex">
      <Table striped bordered hover className="mx-auto">
        <thead>
          <tr>
            <th>Количество Туристов</th>
            <th>Стоимость</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Мини-групповая прогулка <br /> (При наборе от 4-х чел по
              субботам)
            </td>
            <td>
              $130 / чел + трансфер <br />
            </td>
          </tr>
          <tr>
            <td>
              Индивидуальная прогулка <br />
              (Максимум 25 человек на борту)
            </td>
            <td>$1250 + трансфер</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default Pricetable;
