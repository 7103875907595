import './App.css';
import Navbar from "./Components/Header/Header"
import Hero from './Components/Hero'
import Landing from './Components/Maipage.js/Landing'
import KumanaSafari from './Components/Kumanasafari/KumanaSafari';
import Trinco2daytour from './Components/Trinco2dayind/Trinco2dayind'
import KandyPerahera from './Components/KandyPerahera/Kandyperahera'
import Individualtours from './Components/IndividualTours/Tours';
import Grouptours from './Components/GroupTours/Excursions';
import Summertours from './Components/SummerTours/Excursions';
import Tendaystour from './Components/10daytour/Tendaystour';
import CarRental from './Components/CarRental/Tendaystour';
import Ella from './Components/SouthTours/YamuElla/Ella'
import Kandy from './Components/SouthTours/YamuKandy/Kandy'
import Explore from './Components/SouthTours/YamuExplore/Explore'
import EastExplore from './Components/EastTours/2daytour/Explore'
import EastElla from './Components/EastTours/YamuEllaPasi/Ella'
import EastPolonnaruwa from './Components/EastTours/Polonnaruwa/Polonnaruawa'
import Yala from './Components/SouthTours/YamuSafari/Yala'
import Tourbuilder from "./Components/Tourbuilder/Tourbuilder" 
import TransferTourbuilder from "./Components/TransferTour/TransferTourbuilder"
import Transfermain from './Components/Transfers/Transfermain';
import Ordertransfer from './Components/Transfers/Ordertransfer';

import "bootstrap/dist/css/bootstrap.min.css";
import ScrollToTop from "./ScrollToTop"; 

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Aboutus from './Components/Minicomponents/About/Aboutus';
import SunsetYacht from './Components/SouthTours/SunsetYacht/SunsetYacht'; 
import SunsetYachtEast from './Components/EastTours/SunsetYacht/SunsetYacht';  
import Ridiyagama from './Components/SouthTours/Ridiyagama/Ridiyagama';
import Trinco from './Components/EastTours/Trinco/Trinco';
import Minneriya from './Components/EastTours/Minneriya/Minneriya';
import Sigiriya from './Components/EastTours/Sigiriya/Sigiriya';
import Galle from './Components/SouthTours/YamuGalle/Galle';



function App() {
  return (
    <div className="App">
      <Navbar />
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/tours" element={<Individualtours />} />
          <Route path="/excursions" element={<Grouptours />} />
          <Route path="/summertours" element={<Summertours />} />
          <Route path="/transfers" element={<Transfermain />} />
          <Route path="ordertransfer" element={<Ordertransfer />} />
          <Route path="Kumanasafari" element={<KumanaSafari />} />
          <Route path="Ridiyagamasafari" element={<Ridiyagama />} />
          <Route path="tourbuilder" element={<Tourbuilder />} />
          <Route path="transfertourbuilder" element={<TransferTourbuilder />} />
          <Route path="yamuella" element={<Ella />} />
          <Route path="yamusunsetyacht" element={<SunsetYacht />} />
          <Route path="yamukandy" element={<Kandy />} />
          <Route path="yamuexplore" element={<Explore />} />
          <Route path="yamueastexplore" element={<EastExplore />} />
          <Route path="yamueastella" element={<EastElla />} />
          <Route path="yamupolonnaruwa" element={<EastPolonnaruwa />} />
          <Route path="yamutrinco" element={<Trinco />} />
          <Route path="yamuminneriya" element={<Minneriya />} />
          <Route path="yamusigiriya" element={<Sigiriya />} />
          <Route path="yamusunsetyachteast" element={<SunsetYachtEast />} />
          <Route path="yamuyala" element={<Yala />} />
          <Route path="yamugalle" element={<Galle />} />
          <Route path="tendaystour" element={<Tendaystour />} />
          <Route path="carrental" element={<CarRental />} />
          <Route path="kandyperahera" element={<KandyPerahera />} />
          <Route path="Trinco2dayindividual" element={<Trinco2daytour />} />
          <Route path="hero" element={<Hero />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
