import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { FaCheck, FaTimes } from "react-icons/fa";
// import Photogrid from "./Photogrid";
import Pricetable from "./Pricetable"
import Booktour from "../../Minicomponents/Booktour";
import Gallary8 from "./Gallary8";

const Details = () => {
  return (
    <div className="container d-flex p-0">
      <div className="col-12 mt-3 p-0 row mx-auto">
        <div className="col-12 col-md-3 row p-1 mx-auto">
          <div className="col-12  d-flex border-thin">
            <div className="col-12">
              <h5 className="text-center my-auto">Продолжительность</h5>
              <p className="text-center my-auto">6 часов</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 row p-1 mx-auto">
          <div className="col-12  d-flex border-thin">
            <div className="col-12">
              <h5 className="text-center my-auto">Стоимость</h5>
              <p className="text-center my-auto">от $70</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 row p-1 mx-auto ">
          <div className="col-12  d-flex border-thin">
            <div className="col-12">
              <h5 className="text-center my-auto">Групповый тур</h5>
              <p className="text-center my-auto">По Вторникам</p>
            </div>
          </div>
        </div>
        <div className="p-1 my-3">
          <h2 className="mt-2 deco-text">Обзор</h2>
          <p className="deco-text">
            Погрузитесь в историю Полоннарувы вместе с русскоговорящим гидом в
            течение полудня. Разгадайте тайны древних королевств, прогуливаясь
            по храмам и палацам. Насладитесь неповторимой атмосферой и
            богатством культурного наследия этого удивительного места.
          </p>
        </div>

        <div className="p-1 my-3">
          <h2 className="mt-2 deco-text">Маршрут</h2>
          <Accordion defaultActiveKey="0" alwaysOpen className="deco-text">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="deco-text">
                Выезд 13:00
              </Accordion.Header>
              <Accordion.Body>
                Водитель прибудет в ваш отель или на вашу виллу, чтобы забрать
                вас. Если водитель не может найти ваш отель с помощью карт
                Google, он может позвонить вам.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Дворцовый комплекс</Accordion.Header>
              <Accordion.Body>
                Комплекс дворцов в Полоннаруве представляет собой величественную
                архитектурную достопримечательность, олицетворение величия
                древней цивилизации Шри Ланки. Состоящий из разнообразных
                структур, включая павильоны и аудитории, этот комплекс отражает
                богатство и культурное наследие прошлых эпох.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Религиозный комплекс</Accordion.Header>
              <Accordion.Body>
                Религиозный комплекс в Полоннаруве — это уникальное собрание
                святынь и храмов, отражающих богатство и многогранность
                религиозной практики древних цивилизаций Шри Ланки. Здесь можно
                восхититься величественными ступами, медитировать в монастырях и
                ощутить дух духовной истории этого удивительного места.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Малый Пик Адама</Accordion.Header>
              <Accordion.Body>
                Поход на Малый пик Адама в Шри-Ланке — одно из самых эпических
                занятий в Элле. На вершину можно легко подняться, так как есть
                отмеченная тропа, ведущая на гору, с множеством указателей,
                указывающих путь.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Каменный храм</Accordion.Header>
              <Accordion.Body>
                "Gal Viharaya" в Полоннаруве – это впечатляющий комплекс
                буддийских скульптур, высеченных из скалы. Состоящий из четырех
                статуй Будды, включая статую лежащего Будды длиной 14 метров,
                это место вдохновляет своей красотой и духовной атмосферой,
                привлекая паломников и туристов.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>Возвращение в отель (18:30)</Accordion.Header>
              <Accordion.Body>
                Ваш водитель отвезет вас в отель примерно в 18:30.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="p-1 row col-12 deco-text my-3">
          <h2 className="mt-2">Включено / Не включено</h2>
          <div className=" col-12 p-0">
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Транспорт </p>
            </div>
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Опытный рускоговорящий гид</p>
            </div>
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Входные билеты</p>
            </div>
            <div className="col-12 d-flex">
              <FaCheck className="my-auto green-text" />{" "}
              <p className="my-auto ml-2">Закуски / напитки</p>
            </div>
          </div>
          <div className="col-12 p-0">
            <div className="col-12 d-flex">
              <FaTimes className="my-auto" />{" "}
              <p className="my-auto ml-2">Обед</p>
            </div>
            <div className="col-12 d-flex">
              <FaTimes className="my-auto" />{" "}
              <p className="my-auto ml-2">Личные расходы</p>
            </div>
          </div>
        </div>
        <div className="p-1 col-12 mx-auto deco-text my-3">
          <h2 className="mt-2">Стоимость</h2>
          <div className="col-12 p-0">
            <Pricetable />
          </div>
        </div>
        <div className="p-1 col-12 mx-auto deco-text my-3">
          <h2 className="mt-2">Галерея</h2>
          <div className="col-12 p-0">
            <Gallary8 />
          </div>  
        </div>
        <div className="p-1 row col-12 deco-text my-3 mx-auto">
          <div className="p-0 col-12">
            <Booktour tour="Полоннарува" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
