import React from 'react'
import Accordion from "react-bootstrap/Accordion";
import { FaCheck, FaTelegramPlane, FaWhatsapp, FaTimes } from "react-icons/fa";
import { AiFillPhone } from "react-icons/ai";
import Card from "react-bootstrap/Card";
// import Carousel from "react-bootstrap/Carousel";

const Details = () => {
    return (
      <div className="container d-flex">
        <div className="col-12 my-3 p-1 row mx-auto">
          <div className="col-12 col-md-3 row p-1 mx-auto">
            <div className="col-12  d-flex border-thin">
              <div className="col-12">
                <h5 className="text-center my-auto">Продолжительность</h5>
                <p className="text-center my-auto">24 часа</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 row p-1 mx-auto">
            <div className="col-12  d-flex border-thin">
              <div className="col-12">
                <h5 className="text-center my-auto">Стоимость</h5>
                <p className="text-center my-auto">$130*</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 row p-1 mx-auto ">
            <div className="col-12  d-flex border-thin">
              <div className="col-12">
                <h5 className="text-center my-auto">Групповый тур</h5>
                <p className="text-center my-auto">11.08.2022</p>
              </div>
            </div>
          </div>
          <div className="p-1 my-3">
            <h2 className="mt-2 deco-text">Обзор</h2>
            <p className="deco-text">
              Эсала Перахера (праздник зуба Будды) — это грандиозный праздник
              августа, который проводится на Шри-Ланке. Это очень грандиозно с
              элегантными костюмами. Проходя в августе в Канди, он стал
              своеобразным символом Шри-Ланки. Это буддийский фестиваль,
              состоящий из танцев и красиво украшенных слонов. Есть танцы с
              огнем, танцы с кнутом, танцы Канди и различные другие культурные
              танцы. Слоны обычно украшены роскошными одеждами.
            </p>
          </div>

          <div className="p-1 my-3">
            <h2 className="mt-2 deco-text">Маршрут</h2>
            <Accordion defaultActiveKey="0" className="deco-text">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="deco-text">
                  Выезд 03:00
                </Accordion.Header>
                <Accordion.Body>
                  <h5 className="deco-text">Унаватуна : 03.00</h5>
                  <h5 className="deco-text">Хиккадува : 03.30</h5>
                  <h5 className="deco-text">Бентота : 04.00</h5>
                  Водитель прибудет в ваш отель или на вашу виллу, чтобы забрать
                  вас. Если водитель не может найти ваш отель с помощью карт
                  Google, он может позвонить вам. Вы должны оплатить полную
                  сумму при встрече с водителем.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Отель Elephant Bay</Accordion.Header>
                <Accordion.Body>
                  Завтрак в отеле Elephant Bay, с видом на купание слонов. Вы
                  также можете пользоваться бассейном отеля. Если прилив на реке
                  высокий, купание слонов не происходит. В таком случае мы
                  отвезем вас в слоновий питомник.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Сад специй</Accordion.Header>
                <Accordion.Body>
                  Мы отвезем вас в сад трав и специй, где вы сможете узнать об
                  истории специй и купить аюрведические лекарства, кремы,
                  бальзамы и специи, чтобы забрать их их в свою страну.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Башня Амбулувава</Accordion.Header>
                <Accordion.Body>
                  Горная вершина Амбулувава имеет высоту 3567 футов над уровнем
                  моря. Он расположен на высоте более 1000 футов над городом
                  Гампола. Башня расположена на вершине горной вершины.
                  Поскольку в окрестностях нет других гор, а благодаря своему
                  уникальному местоположению, башня Амбулувава имеет прекрасный
                  вид издалека и наоборот. Башня видна с железнодорожного
                  вокзала Гампола.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Город Кэнди</Accordion.Header>
                <Accordion.Body>
                  Город Канди — один из самых красивых городов острова. Ваш гид
                  проведет вас на мини-тур по городу в город Канди, озеро Канди
                  и, если возможно, в Храм зуба Будды. Билеты в Храм зуба зуба в
                  стоимость не входят.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>Кэнди Перахара</Accordion.Header>
                <Accordion.Body>
                  Канди перахера начинается в 19.00. Гид проводит вас к
                  забронированным местам до начала фестиваля. Фестиваль длится
                  около 3 часов. После фестиваля вас встретит водитель.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>Возвращение на юг</Accordion.Header>
                <Accordion.Body>
                  Ваш водитель отвезет вас в отель около 03:00 утра следующего
                  дня.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="p-1 row col-12 deco-text my-3">
            <h2 className="mt-2">Включено / Не включено</h2>
            <div className=" col-12 p-0">
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Транспорт в микроавтобусе</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Опытный рускоговорящий гид</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Входные билеты</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Зарезервированные места</p>
              </div>
              <div className="col-12 d-flex">
                <FaCheck className="my-auto green-text" />{" "}
                <p className="my-auto ml-2">Завтрак</p>
              </div>
            </div>
            <div className="col-12 p-0">
              <div className="col-12 d-flex">
                <FaTimes className="my-auto" />{" "}
                <p className="my-auto ml-2">Обед / ужин</p>
              </div>
              <div className="col-12 d-flex">
                <FaTimes className="my-auto" />{" "}
                <p className="my-auto ml-2">Личные расходы</p>
              </div>
            </div>
            <p className='mt-5'>*стоимость тура при наборе от 4-х человек : $130/чел</p>
          </div>
          <div className="p-1 deco-text my-3">
            {/* <h2 className="mt-2">Gallary</h2> */}
            <div className="row col-12 p-0 mx-auto">
              <div className="col-6 col-md-3 p-1 mx-auto">
                <Card className="card-width">
                  <Card.Img
                    variant="top"
                    src="https://live.staticflickr.com/65535/50183728751_9466c5aef0_k.jpg"
                  />

                  {/* <Card.Body>
                    <p className="text-center my-auto">Nuwara Eliya</p>
                  </Card.Body> */}
                </Card>
              </div>
              <div className="col-6 col-md-3 p-1 mx-auto">
                <Card>
                  <Card.Img
                    variant="top"
                    src="https://live.staticflickr.com/65535/50183987177_9b33c7d86b_k.jpg"
                  />

                  {/* <Card.Body>
                    <p className="text-center my-auto">Nuwara Eliya</p>
                  </Card.Body> */}
                </Card>
              </div>
              <div className="col-6 col-md-3 p-1 mx-auto">
                <Card>
                  <Card.Img
                    variant="top"
                    src="https://live.staticflickr.com/65535/50183729291_94ff1c98d6_k.jpg"
                  />
                  {/* <Card.Body>
                    <p className="text-center my-auto">Nuwara Eliya</p>
                  </Card.Body> */}
                </Card>
              </div>
              <div className="col-6 col-md-3 p-1 mx-auto">
                <Card>
                  <Card.Img
                    variant="top"
                    src="https://live.staticflickr.com/65535/50183987942_4d1a25b84a_k.jpg"
                  />

                  {/* <Card.Body>
                    <p className="text-center my-auto">Nuwara Eliya</p>
                  </Card.Body> */}
                </Card>
              </div>
              <div className="col-6 col-md-3 p-1 mx-auto">
                <Card>
                  <Card.Img
                    variant="top"
                    src="https://live.staticflickr.com/65535/50183730076_c2f8d0e527_k.jpg"
                  />
                  {/* <Card.Body>
                    <p className="text-center my-auto">Nuwara Eliya</p>
                  </Card.Body> */}
                </Card>
              </div>
              <div className="col-6 col-md-3 p-1 mx-auto">
                <Card>
                  <Card.Img
                    variant="top"
                    src="https://live.staticflickr.com/65535/50183988447_468a2c0eca_k.jpg"
                  />
                  {/* <Card.Body>
                    <p className="text-center my-auto">Nuwara Eliya</p>
                  </Card.Body> */}
                </Card>
              </div>
              {/* <div className="col-6 col-md-3 p-1 mx-auto">
                <Card>
                  <Card.Img
                    variant="top"
                    src="https://images.unsplash.com/photo-1578517929034-db013fd86597?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                  />
                  <Card.Body>
                    <p className="text-center my-auto">Nuwara Eliya</p>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-6 col-md-3 p-1 mx-auto">
                <Card>
                  <Card.Img
                    variant="top"
                    src="https://images.unsplash.com/photo-1578517929034-db013fd86597?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                  />
                  <Card.Body>
                    <p className="text-center my-auto">Nuwara Eliya</p>
                  </Card.Body>
                </Card>
              </div> */}
            </div>
          </div>
          {/* <div className="p-1 deco-text my-3">
            <h2 className="mt-2">Reviews</h2>
            <div className="col-12 col-md-6 p-0 mx-auto">
              <Carousel variant="dark">
                <Carousel.Item>
                  <div className="review-box row col-12 py-5">
                    <div className="d-flex col-12">
                      <div className="client mx-auto"></div>
                    </div>
                    <div className="col-8 mx-auto">
                      {" "}
                      <p className="text-center">Michael Stevens</p>
                    </div>
                    <div className="col-8 mx-auto">
                      <p className="text-center">
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Eum aperiam quis dolore vitae ut tempore
                        necessitatibus facere. Rem fugiat ullam tempore
                        asperiores corporis dolor, cupiditate facere dicta
                        corrupti modi reiciendis.
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="review-box row col-12 py-5">
                    <div className="d-flex col-12">
                      <div className="client mx-auto"></div>
                    </div>
                    <div className="col-8 mx-auto">
                      {" "}
                      <p className="text-center">Michael Stevens</p>
                    </div>
                    <div className="col-8 mx-auto">
                      <p className="text-center">
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Eum aperiam quis dolore vitae ut tempore
                        necessitatibus facere. Rem fugiat ullam tempore
                        asperiores corporis dolor, cupiditate facere dicta
                        corrupti modi reiciendis.
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="review-box row col-12 py-5">
                    <div className="d-flex col-12">
                      <div className="client mx-auto"></div>
                    </div>
                    <div className="col-8 mx-auto">
                      {" "}
                      <p className="text-center">Michael Stevens</p>
                    </div>
                    <div className="col-8 mx-auto">
                      <p className="text-center">
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Eum aperiam quis dolore vitae ut tempore
                        necessitatibus facere. Rem fugiat ullam tempore
                        asperiores corporis dolor, cupiditate facere dicta
                        corrupti modi reiciendis.
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </div> */}
          <div className="p-1 deco-text my-3">
            <h2 className="mt-2">Свяжитесь с нами</h2>
            <div className="col-12 p-0">
              <div className="col-12 d-flex">
                <AiFillPhone className="my-auto" />{" "}
                <p className="my-auto ml-2">+7 929 986 53 82</p>
              </div>
              <div className="col-12 d-flex">
                <FaWhatsapp className="my-auto" />{" "}
                <p className="my-auto ml-2">+7 929 986 53 82</p>
              </div>
              <div className="col-12 d-flex">
                <FaTelegramPlane className="my-auto" />{" "}
                <p className="my-auto ml-2">@yamutours1</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Details