import React from 'react'
import { Accordion } from 'react-bootstrap';
import CarDriver1 from "../../Images/Renting/cardrivermain2.jpg";
import CarDriver2 from "../../Images/Renting/cardrivermain.jpg";
import CarDriver3 from "../../Images/Renting/cardrivermain3.png";

const Itinery = () => {
  return (
    <div className="container my-4">
      <div className="row p-0 mx-auto col-12">
        <div className="col-12 col-md-12 p-0 mx-auto ">
          <div className="col-12 p-0 mx-auto d-flex my-2">
            <span className="yellow-span my-auto mr-1"></span>
            <h2 className="my-auto">Наши Машины</h2>
          </div>
          <div className="row col-12 p-0 mx-auto">
            <div className="col-12 col-md-3 p-1 mx-auto">
              <div className="col-12 m-1 mx-auto border-thin car-type">
                <div className="row col-12 mx-auto p-0">
                  <img
                    src={CarDriver1}
                    alt=""
                    className="col-12 cardrivermain mx-auto"
                  />
                  <div className="col-12">
                    <h5 className="text-center">Компактные Машины</h5>
                    <p className="text-center">2-3 пассажира, 1-2 чемодана</p>
                    <p className="text-center">120км/день</p>
                    <p className="text-center">Toyota Aqua</p>
                    <p className="text-center">Honda Fit</p>
                    <p className="text-center">Suzuki Wagon R</p>
                  </div>

                  <div className="row col-12 p-2 mx-auto border-thin">
                    <p className="text-center my-auto">$60/день</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 p-1 mx-auto">
              <div className="col-12 m-1 mx-auto border-thin car-type">
                <div className="row col-12 mx-auto p-0">
                  <img
                    src={CarDriver2}
                    alt=""
                    className="col-12 cardrivermain mx-auto"
                  />
                  <div className="col-12">
                    <h5 className="text-center">Машины Комфорт</h5>
                    <p className="text-center">3 пассажира, 3-4 чемодана</p>
                    <p className="text-center">150км/день</p>
                    <p className="text-center">Toyota Prius</p>
                    <p className="text-center">Honda Shuttle</p>
                    <p className="text-center">Toyota Premio</p>
                  </div>
                  <div className="row col-12 p-2 mx-auto border-thin">
                    <p className="text-center my-auto">$75/день</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 p-1 mx-auto">
              <div className="col-12 m-1 mx-auto border-thin car-type">
                <div className="row col-12 mx-auto p-0">
                  <img
                    src={CarDriver3}
                    alt=""
                    className="col-12 cardrivermain mx-auto"
                  />
                  <div className="col-12">
                    <h5 className="text-center">Минивены</h5>
                    <p className="text-center">
                      4-9 пассажиров, до 9 чемоданов
                    </p>
                    <p className="text-center">120км/день</p>
                    <p className="text-center">Toyota Noah</p>
                    <p className="text-center">Toyota Hiace</p>
                  </div>
                  <div className="row col-12 p-2 mx-auto border-thin">
                    <p className="text-center my-auto">от $85/день</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-1 p-0 mx-auto"></div>
        <div className="col-12 col-md-4 p-0 mx-auto"></div>
      </div>
    </div>
  );
}

export default Itinery