import React, { useState } from "react";
import { Alert, Form } from "react-bootstrap";
import {
  FaChevronCircleDown,
  FaChevronCircleUp
} from "react-icons/fa";
import ActivityCarousel from "./ActivityCarousel";
import EllaCarousel from "./EllaCarousel";
import KandyCarousel from "./KandyCarousel";
import NuwaraEliyaCarousel from "./NuwaraEliyaCarousel";
import SafariCarousel from "./SafariCarousel";
import SigiriyaCarousel from "./SigiriyaCarousel";

const axios = require("axios").default;

const Builder = () => {
  const [data, setData] = useState({ 
    name: "",
    startcity: "Colombo",
    endcity: "",
    guide: "Russian",
    othersites: "",
    date: "",
    budget: "150",
    phone: "",
    tg: "",
    comments: "",
  });

  // Add Tourists

  // Adults

  const [adult, setAdult] = useState(2);

  const reduceAdult = () => {
    setAdult(adult - 1);
  };

  const addAdult = () => {
    setAdult(adult + 1);
  };

  //Kids >2
  const [kids2, setKids2] = useState(0);

  const reduceKids2 = () => {
    setKids2(kids2 - 1);
  };

  const addKids2 = () => {
    setKids2(kids2 + 1);
  };

  //Kids >6
  const [kids6, setKids6] = useState(0);

  const reduceKids6 = () => {
    setKids6(kids6 - 1);
  };

  const addKids6 = () => {
    setKids6(kids6 + 1);
  };

  //Kids >12
  const [kids12, setKids12] = useState(0);

  const reduceKids12 = () => {
    setKids12(kids12 - 1);
  };

  const addKids12 = () => {
    setKids12(kids12 + 1);
  };

  //Add Days
  const [days, setDays] = useState(2);

  const reduceDays = () => {
    setDays(days - 1);
  };

  const addDays = () => {
    setDays(days + 1);
  };

  //Add Basic details
  const onChangeData = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setPhoneAlert("d-none")
  };

  // Day 1 
  const [day1, setDay1] = useState({
    hotel: false,
    medium: false,
    high: false,
  });

  const onChangeDay1 = (e) => {
    setDay1({ ...day1, [e.target.name]: e.target.checked });
  };

  // Select Places

  const [places, setPlaces] = useState({
    pinnawala: false,
    millenium: false,
    spice: false,
    stones: false,
    daladamaligawa: false,
    kandycity: false,
    peradeniya: false,
    ambuluwawa: false,
    ramboda: false,
    tea: false,
    nuwaraeliyacity: false,
    hortonplains: false,
    ellatrain: false,
    nuwaraeliyatrain30: false,
    ravana: false,
    ninearch: false,
    littleadam: false,
    ravanapool: false,
    zipline: false,
    nuwaraeliyatrain: false,
    ellatrain30: false,
    sigiriya: false,
    pidurangala: false,  
    anuradhapura: false,
    polonnaruwa: false,
    dambulla: false,
    village: false,
    kovil: false,
    yala: false,
    udawalawa: false,
    kaudulla: false,
    rafting: false,
    diyaluma: false,
    sinharaja: false,
    bigadam: false,
    camping: false,
  });

  const onChangePlaces = (e) => {
    setPlaces({ ...places, [e.target.name]: e.target.checked });
  };

  const TOKEN = "5382115648:AAHqm_H-_8AxxABLKceEQClxCU9UjcZO-EE";
  const CHAT_ID = "-1001620924289";
  const URI_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;
  const [alert, setAlert] = useState("d-none");
  const [phoneAlert, setPhoneAlert] = useState("d-none")

  var show100 = "" 
  var totaltourists = (adult + kids2 + kids6 + kids12)

  const checkBudget = (e) => {
    if (data.budget === "100" && totaltourists < 3 && data.guide === "Russian" && days <3)  {
      show100 =
        "За этот бюджет индивидуальная экскурсия может быть организована только с англоговорящим гид-водителем. ";
    }
  }

   var totalprice = (data.budget*totaltourists*days) 

  function submit(e) {
    e.preventDefault();

    let message = `<b>Трансфер-Тур</b> \n`;
    message += `<b>Name:</b> ${data.name} \n`;
    message += `<b>Start:</b> ${data.startcity} \n`;
    message += `<b>End:</b> ${data.endcity} \n`;
    message += `<b>Days:</b> ${days} \n`;
    message += `<b>Guide:</b> ${data.guide} \n`;
    message += `<b>Date:</b> ${data.date} \n`;
    message += `<b>Budget:</b> ${data.budget} \n`;
    message += `<b>Adults:</b> ${adult} \n`;
    message += `<b>Kids2:</b> ${kids2} \n`;
    message += `<b>Kids6:</b> ${kids6} \n`;
    message += `<b>Kids12:</b> ${kids12} \n`;
    message += `<b>Othersites:</b> ${data.othersites} \n`;
    message += `<b>Phone:</b> ${data.phone} \n`;
    message += `<b>TG: </b> ${data.tg} \n`;
    message += `<b>comments:</b> ${data.comments} \n`;
    message += `<b>pinnawala:</b> ${places.pinnawala} \n`;
    message += `<b>millenium:</b> ${places.millenium} \n`;
    message += `<b>spice:</b> ${places.spice} \n`;
    message += `<b>stones:</b> ${places.stones} \n`;
    message += `<b>daladamaligawa:</b> ${places.daladamaligawa} \n`;
    message += `<b>kandycity:</b> ${places.kandycity} \n`;
    message += `<b>peradeniya:</b> ${places.peradeniya} \n`;
    message += `<b>ambuluwawa:</b> ${places.ambuluwawa} \n`;
    message += `<b>ramboda:</b> ${places.ramboda} \n`;
    message += `<b>nuwaraeliyacity:</b> ${places.nuwaraeliyacity} \n`;
    message += `<b>tea:</b> ${places.tea} \n`;
    message += `<b>hortonplains:</b> ${places.hortonplains} \n`;
    message += `<b>ellatrain:</b> ${places.ellatrain} \n`;
    message += `<b>nuwaraeliyatrain30:</b> ${places.nuwaraeliyatrain30} \n`;
    message += `<b>ravana:</b> ${places.ravana} \n`;
    message += `<b>ninearch:</b> ${places.ninearch} \n`;
    message += `<b>littleadam:</b> ${places.littleadam} \n`;
    message += `<b>zipline:</b> ${places.zipline} \n`;
    message += `<b>ellatrain:</b> ${places.ellatrain} \n`;
    message += `<b>nuwaraeliyatrain:</b> ${places.nuwaraeliyatrain} \n`;
    message += `<b>ellatrain30:</b> ${places.ellatrain30} \n`;
    message += `<b>sigiriya:</b> ${places.sigiriya} \n`;
    message += `<b>pidurangala:</b> ${places.pidurangala} \n`;
    message += `<b>anuradhapura:</b> ${places.anuradhapura} \n`;
    message += `<b>polonnaruwa:</b> ${places.polonnaruwa} \n`;
    message += `<b>dambulla:</b> ${places.dambulla} \n`;
    message += `<b>village:</b> ${places.village} \n`;
    message += `<b>kovil:</b> ${places.kovil} \n`;
    message += `<b>yala:</b> ${places.yala} \n`;
    message += `<b>udawalawa:</b> ${places.udawalawa} \n`;
    message += `<b>minneriya:</b> ${places.kaudulla} \n`;
    message += `<b>rafting:</b> ${places.rafting} \n`;
    message += `<b>diyaluma:</b> ${places.diyaluma} \n`;
    message += `<b>sinharaja:</b> ${places.sinharaja} \n`;
    message += `<b>bigadam:</b> ${places.bigadam} \n`;
    message += `<b>camping:</b> ${places.camping} \n`;
    message += `<b>day1hotel:</b> ${day1.hotel} \n`;
    message += `<b>day1medium:</b> ${day1.medium} \n`;
    message += `<b>day1high:</b> ${day1.high} \n`;



    if (data.phone === "" && data.tg === "") { setPhoneAlert(""); } else {
      axios
        .post(URI_API, {
          chat_id: CHAT_ID,
          parse_mode: "html",
          text: message,
        })
        .then((res) => {
          setData({
            name: "",
            startcity: "",
            endcity: "",
            guide: "Russian",
            othersites: "",
            date: "",
            budget: "150",
            phone: "",
            tg: "",
            comments: "",
          });

          setAdult(2);
          setKids12(0);
          setKids2(0);
          setKids6(0);

          setAlert("");
        })
        .catch((err) => {
          console.warn(err);
        })
        .finally(() => {
          console.log(message);
        });
    }

  }


  return (
    <div className="container p-1">
      <p>
        Заполните следующие поля и выберите места, которые вы хотели бы
        посетить. Отправьте данные и наш специалист свяжется с вами с
        вариантами.
      </p>

      <div className="row col-12 mx-auto p-0">
        <div className="col-12 d-flex p-0">
          <Form className="row col-12 mx-auto p-0">
            {/* <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Начало (город)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Коломбо"
                  name="startcity"
                  value={data.startcity}
                  onChange={onChangeData}
                />
                <Form.Text className="text-muted">
                  Возможно начать тур из аэропорта.
                </Form.Text>
              </Form.Group>
            </div> */}
            <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="font-weight-bold">
                  Начало (Аэропорт)
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="startcity"
                  value={data.startcity}
                  onChange={onChangeData}
                >
                  <option value="Colombo">Коломбо</option>
                  <option value="Hambantota">Маттала</option>
                </Form.Select>
              </Form.Group>
            </div>
            <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="font-weight-bold">
                  Окончание тура (город)
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Тринкомали"
                  name="endcity"
                  value={data.endcity}
                  onChange={onChangeData}
                />
              </Form.Group>
            </div>
            <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="font-weight-bold">
                  Количество дней
                </Form.Label>
                <div className="row col-12 p-0 mx-auto">
                  <div className="col-1">
                    <FaChevronCircleDown
                      onClick={reduceDays}
                      className=" mr-3 my-auto makebutton"
                    />
                  </div>
                  <div className="col-1">{days}</div>
                  <div className="col-1">
                    <FaChevronCircleUp
                      onClick={addDays}
                      className="  my-auto makebutton"
                    />
                  </div>
                </div>
              </Form.Group>
            </div>
            <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="font-weight-bold">
                  Количество туристов
                </Form.Label>
                <div className="row col-12 p-0 mx-auto">
                  <div className="col-6">Взрослые (12+) :</div>
                  <div className="col-1">
                    <FaChevronCircleDown
                      onClick={reduceAdult}
                      className=" mr-3 my-auto makebutton"
                    />
                  </div>
                  <div className="col-1">{adult}</div>
                  <div className="col-1">
                    <FaChevronCircleUp
                      onClick={addAdult}
                      className="  my-auto makebutton"
                    />
                  </div>
                </div>
                <div className="row col-12 p-0 mx-auto">
                  <div className="col-6">Дети (до 2г) :</div>
                  <div className="col-1">
                    <FaChevronCircleDown
                      onClick={reduceKids2}
                      className=" mr-3 my-auto makebutton"
                    />
                  </div>
                  <div className="col-1">{kids2}</div>
                  <div className="col-1">
                    <FaChevronCircleUp
                      onClick={addKids2}
                      className="  my-auto makebutton"
                    />
                  </div>
                </div>
                <div className="row col-12 p-0 mx-auto">
                  <div className="col-6">Дети (до 6г) :</div>
                  <div className="col-1">
                    <FaChevronCircleDown
                      onClick={reduceKids6}
                      className=" mr-3 my-auto makebutton"
                    />
                  </div>
                  <div className="col-1">{kids6}</div>
                  <div className="col-1">
                    <FaChevronCircleUp
                      onClick={addKids6}
                      className="  my-auto makebutton"
                    />
                  </div>
                </div>
                <div className="row col-12 p-0 mx-auto">
                  <div className="col-6">Дети (до 12г) :</div>
                  <div className="col-1">
                    <FaChevronCircleDown
                      onClick={reduceKids12}
                      className=" mr-3 my-auto makebutton"
                    />
                  </div>
                  <div className="col-1">{kids12}</div>
                  <div className="col-1">
                    <FaChevronCircleUp
                      onClick={addKids12}
                      className="  my-auto makebutton"
                    />
                  </div>
                </div>
              </Form.Group>
            </div>
            <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="font-weight-bold">Гид</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="guide"
                  value={data.guide}
                  onChange={onChangeData}
                >
                  <option value="Russian">
                    Русскоговорящий гид ($50/день)
                  </option>
                  <option value="English">Англоговорящий гид ($25/день)</option>
                  <option value="Driver">
                    Англоговорящий гид-водитель ($0/день)
                  </option>
                </Form.Select>
              </Form.Group>
            </div>
            <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="font-weight-bold">Бюджет</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="budget"
                  value={data.budget}
                  onChange={onChangeData}
                >
                  <option value="100">До $100 / день / чел</option>
                  <option value="125">До $125 / день / чел</option>
                  <option value="150">До $150 / день / чел</option>
                  <option value="200">До $200 / день / чел</option>
                  <option value="0">Пришлите варианты </option>
                </Form.Select>
                {checkBudget()}
                <Form.Text className="text-muted">
                  Итоговая сумма до ${`${totalprice}`}
                  <br />
                  {/* В стоимость входит транспорт, водитель, гид (по выбору),
                  проживание, входные билеты, питание и т.д. */}
                  <span className="text-danger">{`${show100}`}</span>
                </Form.Text>
                <div></div>
              </Form.Group>
            </div>
            <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3 " controlId="formBasicEmail">
                <div className="row col-12 mx-auto p-0">
                  <div className="col-12 p-0">
                    <p className="my-auto font-weight-bold">
                      Как спланировать день прилета?
                    </p>
                    <Form.Group className="mb-3" id="formGridCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="Хотим в отель рядом с аэропортом, путишествия в следующий день"
                        name="hotel"
                        checked={day1.hotel}
                        onChange={onChangeDay1}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Пара достопримечательностей и в отель"
                        name="medium"
                        checked={day1.medium}
                        onChange={onChangeDay1}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Готовы начинать экскурсию полным ходом из Аэропорта"
                        name="high"
                        checked={day1.high}
                        onChange={onChangeDay1}
                      />
                    </Form.Group>
                  </div>
                </div>
              </Form.Group>
            </div>
            <div className="row col-12 col-md-6 p-0 mx-auto"></div>
            <div className="row col-12 p-0 mx-auto">
              <Form.Group className="mb-1 " controlId="formBasicEmail">
                <Form.Label className="font-weight-bold">
                  Выберите достопримечательности
                </Form.Label>
              </Form.Group>
            </div>
            <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3 " controlId="formBasicEmail">
                <div className="col-12 kandy-background d-flex p-0">
                  <KandyCarousel />
                  {/* <p className="text-light mt-auto mb-0 p-1">Город Канди</p> */}
                </div>

                <div className="row col-12 mx-auto p-0">
                  <div className="col-12 p-0">
                    <p className="my-auto">Город Канди</p>
                    <Form.Group className="mb-3" id="formGridCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="Слоновий питомник Пиннавала (2ч, $$$$)"
                        name="pinnawala"
                        checked={places.pinnawala}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Интерактивная программа со слонами (1ч, $$$)"
                        name="millenium"
                        checked={places.millenium}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Храм Зуба Будды (1,5ч, $$)"
                        name="daladamaligawa"
                        checked={places.daladamaligawa}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Аюрведический сад (0,5ч, $)"
                        name="spice"
                        checked={places.spice}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Кэнди сити тур (1ч, $)"
                        name="kandycity"
                        checked={places.kandycity}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Королевский ботанический сад (2ч, $$)"
                        name="peradeniya"
                        checked={places.peradeniya}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Музей драгоценных камней (0,5ч, $)"
                        name="stones"
                        checked={places.stones}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Башня Амбулувава (1ч, $$$)"
                        name="ambuluwawa"
                        checked={places.ambuluwawa}
                        onChange={onChangePlaces}
                      />
                    </Form.Group>
                  </div>
                </div>
              </Form.Group>
            </div>
            <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3 " controlId="formBasicEmail">
                <div className="col-12 kandy-background d-flex p-0">
                  <NuwaraEliyaCarousel />
                  {/* <p className="text-light mt-auto mb-0 p-1">Город Канди</p> */}
                </div>

                <div className="row col-12 mx-auto p-0">
                  <div className="col-12 p-0">
                    <p className="my-auto">Город Нувара-Элия</p>
                    <Form.Group className="mb-3" id="formGridCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="Водопад Рамбода (0,5ч, $)"
                        name="ramboda"
                        checked={places.ramboda}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Чайная плантация, фабрика (1ч, $)"
                        name="tea"
                        checked={places.tea}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Нувара-Элия сити тур (1ч, $)"
                        name="nuwaraeliyacity"
                        checked={places.nuwaraeliyacity}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Равнины Хортона / край света (6ч, $$$$$)"
                        name="hortonplains"
                        checked={places.hortonplains}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Поезд в Эллу (3ч, $$$)"
                        name="ellatrain"
                        checked={places.ellatrain}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Поезд 30 минут (0,5ч, $)"
                        name="nuwaraeliyatrain30"
                        checked={places.nuwaraeliyatrain30}
                        onChange={onChangePlaces}
                      />
                    </Form.Group>
                  </div>
                </div>
              </Form.Group>
            </div>
            <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3 " controlId="formBasicEmail">
                <div className="col-12 kandy-background d-flex p-0">
                  <EllaCarousel />
                  {/* <p className="text-light mt-auto mb-0 p-1">Город Канди</p> */}
                </div>

                <div className="row col-12 mx-auto p-0">
                  <div className="col-12 p-0">
                    <p className="my-auto">Город Элла</p>
                    <Form.Group className="mb-3" id="formGridCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="Водопад Равана (0,5ч, $)"
                        name="ravana"
                        checked={places.ravana}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="9 арочный мост (1ч, $)"
                        name="ninearch"
                        checked={places.ninearch}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Малый пик адама (1,5ч, $)"
                        name="littleadam"
                        checked={places.littleadam}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Ravana pool club ресторан (2ч, $$$$)"
                        name="ravanapool"
                        checked={places.ravanapool}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Зиплайн (0,5ч, $$$$)"
                        name="zipline"
                        checked={places.zipline}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Поезд в Нувара-Элия (3ч, $$$)"
                        name="nuwaraeliyatrain"
                        checked={places.nuwaraeliyatrain}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Поезд 30 минут (0,5ч, $)"
                        name="ellatrain30"
                        checked={places.ellatrain30}
                        onChange={onChangePlaces}
                      />
                    </Form.Group>
                  </div>
                </div>
              </Form.Group>
            </div>
            <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3 " controlId="formBasicEmail">
                <div className="col-12 kandy-background d-flex p-0">
                  <SigiriyaCarousel />
                  {/* <p className="text-light mt-auto mb-0 p-1">Город Канди</p> */}
                </div>

                <div className="row col-12 mx-auto p-0">
                  <div className="col-12 p-0">
                    <p className="my-auto">
                      Сигирия, Анурадхапура, Полоннарува
                    </p>
                    <Form.Group className="mb-3" id="formGridCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="Скала сигирия (2ч, $$$$$)"
                        name="sigiriya"
                        checked={places.sigiriya}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Скала Пидурангала (1ч, $)"
                        name="pidurangala"
                        checked={places.pidurangala}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Руины в Анурадхапуре (2,5ч, $$$$$)"
                        name="anuradhapura"
                        checked={places.anuradhapura}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Руины в Полоннаруве (2,5ч, $$$$$)"
                        name="polonnaruwa"
                        checked={places.polonnaruwa}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Пещерный храм Дамбулла (1,5ч, $$)"
                        name="dambulla"
                        checked={places.dambulla}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Экскурсия по деревне (2ч, $$)"
                        name="village"
                        checked={places.village}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Индуистский храм (0,5ч, $)"
                        name="kovil"
                        checked={places.kovil}
                        onChange={onChangePlaces}
                      />
                    </Form.Group>
                  </div>
                </div>
              </Form.Group>
            </div>
            <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3 " controlId="formBasicEmail">
                <div className="col-12 kandy-background d-flex p-0">
                  <SafariCarousel />
                  {/* <p className="text-light mt-auto mb-0 p-1">Город Канди</p> */}
                </div>

                <div className="row col-12 mx-auto p-0">
                  <div className="col-12 p-0">
                    <p className="my-auto">Сафари</p>
                    <Form.Group className="mb-3" id="formGridCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="Национальный парк Яла (4ч, $$$$$)"
                        name="yala"
                        checked={places.yala}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Национальный парк Удавалава (3ч, $$$$$)"
                        name="udawalawa"
                        checked={places.udawalawa}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Национальный парк Миннерия (3ч, $$$$$)"
                        name="kaudulla"
                        checked={places.kaudulla}
                        onChange={onChangePlaces}
                      />
                    </Form.Group>
                  </div>
                </div>
              </Form.Group>
            </div>
            <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3 " controlId="formBasicEmail">
                <div className="col-12 kandy-background d-flex p-0">
                  <ActivityCarousel />
                  {/* <p className="text-light mt-auto mb-0 p-1">Город Канди</p> */}
                </div>

                <div className="row col-12 mx-auto p-0">
                  <div className="col-12 p-0">
                    <p className="my-auto">Активности</p>
                    <Form.Group className="mb-3" id="formGridCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="Рафтинг (1ч, $$$$$)"
                        name="rafting"
                        checked={places.rafting}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Треккинг - Диялума (3ч, $$$)"
                        name="diyaluma"
                        checked={places.diyaluma}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Поход - Синхараджа (3ч, $$$$)"
                        name="sinharaja"
                        checked={places.sinharaja}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Треккинг - Пик Адама (10ч, $$$)"
                        name="bigadam"
                        checked={places.bigadam}
                        onChange={onChangePlaces}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Кэмпинг (16ч, $$$$$)"
                        name="camping"
                        checked={places.camping}
                        onChange={onChangePlaces}
                      />
                    </Form.Group>
                  </div>
                </div>
              </Form.Group>
            </div>
            <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="font-weight-bold">
                  Любые другие места, которые вы хотели бы посетить.
                </Form.Label>
                <Form.Control
                  as="textarea"
                  aria-label="With textarea"
                  placeholder="Джафна, Тринкомали"
                  name="othersites"
                  value={data.othersites}
                  onChange={onChangeData}
                />
              </Form.Group>
            </div>
            <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="font-weight-bold">
                  Дата, когда вы планируете отправиться на экскурсию.
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="20.12.2022"
                  name="date"
                  value={data.date}
                  onChange={onChangeData}
                />
              </Form.Group>
            </div>
            <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="font-weight-bold">Ваше имя</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Иван Ивонов"
                  name="name"
                  value={data.name}
                  onChange={onChangeData}
                />
              </Form.Group>
            </div>
            <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="font-weight-bold">
                  Номер телефона{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="+7 929 986 53 82"
                  name="phone"
                  value={data.phone}
                  onChange={onChangeData}
                />
              </Form.Group>
            </div>
            <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="font-weight-bold">
                  Телеграм Ник{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="@yamutours1"
                  name="tg"
                  value={data.tg}
                  onChange={onChangeData}
                />
              </Form.Group>
            </div>
            <div className="row col-12 col-md-6 p-0 mx-auto">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="font-weight-bold">
                  Коментарии / требования
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder=""
                  name="comments"
                  value={data.comments}
                  onChange={onChangeData}
                  aria-label="With textarea"
                />
              </Form.Group>
            </div>
            <Alert key="success" variant="danger" className={phoneAlert}>
              Пожалуйста, заполните ваши контактные данные
            </Alert>
            <Alert key="success" variant="success" className={alert}>
              Успешно! Мы свяжемся с вами в ближайшее время.
            </Alert>
            <div
              className="row col-12 col-md-4 makebutton mx-auto "
              onClick={submit}
            >
              <div className="col-12 mx-auto d-flex border-thin">
                <div className="col-12">
                  <h5 className="text-center my-1">Отправить</h5>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Builder;
