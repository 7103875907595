import React from "react";
import Gallery from "react-grid-gallery";


const IMAGES = [
  {
    src: "https://upload.wikimedia.org/wikipedia/commons/5/56/Sri_Lanka_-_029_-_Kandy_Temple_of_the_Tooth.jpg",
    thumbnail:
      "https://upload.wikimedia.org/wikipedia/commons/5/56/Sri_Lanka_-_029_-_Kandy_Temple_of_the_Tooth.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 212,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://keepcalmandwander.com/wp-content/uploads/2018/02/Kandy-Temple-of-the-Tooth-Sri-Lanka-9.jpg",
    thumbnail:
      "https://keepcalmandwander.com/wp-content/uploads/2018/02/Kandy-Temple-of-the-Tooth-Sri-Lanka-9.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 212,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://www.greatgardensoftheworld.com/wp-content/uploads/2020/01/GALLERY_RBG-5.jpg",
    thumbnail:
      "https://www.greatgardensoftheworld.com/wp-content/uploads/2020/01/GALLERY_RBG-5.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 212,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://nexttravelsrilanka.com/wp-content/uploads/2021/03/A-path-by-the-landscaped-lawns-with-colourful-fauna-at-the-Peradeniya-Botanical-Garden.jpg",
    thumbnail:
      "https://nexttravelsrilanka.com/wp-content/uploads/2021/03/A-path-by-the-landscaped-lawns-with-colourful-fauna-at-the-Peradeniya-Botanical-Garden.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 212,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://images.squarespace-cdn.com/content/v1/53170656e4b04f773bf88c62/1410792765034-OXYD914S714E4NH3MQH0/pinnawala-elephant-orphanage-sri-lanka-invite-to-paradise-river-2.jpg?format=1000w",
    thumbnail:
      "https://images.squarespace-cdn.com/content/v1/53170656e4b04f773bf88c62/1410792765034-OXYD914S714E4NH3MQH0/pinnawala-elephant-orphanage-sri-lanka-invite-to-paradise-river-2.jpg?format=1000w",
    thumbnailWidth: 300,
    thumbnailHeight: 212,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://www.travelmapsrilanka.com/img/pinnawala/children-feeding-elephants-at-pinnawala-elephant-orphanage.jpg",
    thumbnail:
      "https://www.travelmapsrilanka.com/img/pinnawala/children-feeding-elephants-at-pinnawala-elephant-orphanage.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 212,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://meetinsrilanka.com/wp-content/uploads/2021/05/ambuluwawa-tower-sri-lanka-the-travelizer-featured.jpg",
    thumbnail:
      "https://meetinsrilanka.com/wp-content/uploads/2021/05/ambuluwawa-tower-sri-lanka-the-travelizer-featured.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 212,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://mediaim.expedia.com/localexpert/3390697/3e3a767b-3a2d-4b14-83d5-e648c81c5831.jpg?impolicy=resizecrop&rw=1005&rh=565",
    thumbnail:
      "https://mediaim.expedia.com/localexpert/3390697/3e3a767b-3a2d-4b14-83d5-e648c81c5831.jpg?impolicy=resizecrop&rw=1005&rh=565",
    thumbnailWidth: 300,
    thumbnailHeight: 212,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "http://creativeminds.lk/demo/directory/wp-content/uploads/2018/10/1-3.png",
    thumbnail:
      "http://creativeminds.lk/demo/directory/wp-content/uploads/2018/10/1-3.png",
    thumbnailWidth: 300,
    thumbnailHeight: 212,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://i.pinimg.com/originals/bb/40/c4/bb40c40a11900ce9b1fce312df19aadb.jpg",
    thumbnail:
      "https://i.pinimg.com/originals/bb/40/c4/bb40c40a11900ce9b1fce312df19aadb.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 212,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
];

const Photogrid = () => {
  return <Gallery images={IMAGES} />;
};

export default Photogrid;
