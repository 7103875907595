import React from 'react'
import { Carousel } from "react-bootstrap";

const ActivityCarousel = () => {
  return (
    <Carousel className="col-12 p-0" controls={false} indicators={false} fade>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://www.journeyera.com/wp-content/uploads/2017/10/diyulama-falls-waterfall-sri-lanka-badulla-5494.jpg.webp"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://media.tacdn.com/media/attractions-splice-spp-674x446/07/36/82/81.jpg"
          alt="Second slide"  
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://www.yovoyagin.com/uploads/0000/76/2022/03/25/04.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default ActivityCarousel