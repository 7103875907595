import React from 'react'
import { Carousel } from "react-bootstrap";

const NuwaraEliyaCarousel = () => {
  return (
    <Carousel className="col-12 p-0" controls={false} indicators={false} fade>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://avatars.mds.yandex.net/get-altay/1992662/2a0000016e65aea739f4a630b71e6e9f4579/XXL"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://media.tacdn.com/media/attractions-splice-spp-674x446/0b/01/82/a2.jpg"
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://media.tacdn.com/media/attractions-splice-spp-674x446/0b/39/a4/db.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://www.attractionsinsrilanka.com/wp-content/uploads/2020/07/Nuwara-Eliya-Post-Office.jpg"
          alt="Forth slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://live.staticflickr.com/5483/14103810056_b9c94f2ba4_b.jpg"
          alt="Fifth slide"
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default NuwaraEliyaCarousel