import React from 'react'
import { Carousel } from "react-bootstrap";

const ActivityCarousel = () => {
  return (
    <Carousel className="col-12 p-0" controls={false} indicators={false} fade>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://www.journeyera.com/wp-content/uploads/2017/10/diyulama-falls-waterfall-sri-lanka-badulla-5494.jpg.webp"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://media.tacdn.com/media/attractions-splice-spp-674x446/07/36/82/81.jpg"
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://images.luxuryhotel.guru/hotelimage.php?p_id=4615202&code=0f2f86a5cfbfdd4e97b438c5d18b45f9&webpage=glamping-guide.com&link=https%3A%2F%2Fsubdomain.cloudimg.io%2Fcrop%2F1024x768%2Fq70.fcontrast10.fbright0.fsharp5%2Fhttps%3A%2F%2Fq-xx.bstatic.com%2Fxdata%2Fimages%2Fhotel%2Fmax1536%2F206166983.jpg%3Fk%3D4c78683db05ef3e4cd089134de7434c9b8078c60f7b32d699bef2f55e33b5ea8%26o%3D"
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default ActivityCarousel