import React from "react";
import Table from "react-bootstrap/Table";

const Pricetable = () => {
  return (
    <div className="">
      <Table striped bordered hover className="mx-auto">
        <thead>
          <tr>
            <th>Количество Туристов</th>
            <th>Стоимость</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Групповые экскурсий <br /> (По средам и пятницам)
            </td>
            <td>
              $60 / чел <br />
            </td>
          </tr>
          <tr>
            <td>
              Индивидуальный тур <br />
              (До 3-х взрослых)
            </td>
            <td>$160/ чел</td>
          </tr>
          <tr>
            <td>
              Индивидуальный тур <br />
              (4 - 5 взрослых )
            </td>
            <td>$125 / чел</td>
          </tr>
          <tr>
            <td>
              Индивидуальный тур <br />
              (От 6-и человек )
            </td>
            <td>от $60 / чел</td>
          </tr>
        </tbody>
      </Table>
      <p>
        Туристам из Бентоты, Калутары, Диквеллы, Тангаллы и Васкадувы
        необходимо доплатить $20 на человека за трансфер до места
        начала экскурсии.
      </p>
    </div>
  );
};

export default Pricetable;
