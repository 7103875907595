import React from 'react'
import { Carousel } from "react-bootstrap";

const SigiriyaCarousel = () => {
  return (
    <Carousel className="col-12 p-0" controls={false} indicators={false} fade>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://cdn.getyourguide.com/img/location/5ca1e9c70eea9.jpeg/99.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://www.thecoastalcampaign.com/wp-content/uploads/2020/01/Pidurangala-Hike.jpg"
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://media.istockphoto.com/photos/mihintale-in-anuradhapura-picture-id1178773961?k=20&m=1178773961&s=612x612&w=0&h=WOCZAoDR81TWqee-uNrU3u_pf92KUZRwxz6VkxHpBgg="
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://img.traveltriangle.com/blog/wp-content/uploads/2018/10/Cover-PolonnaruwaPlacesepb0310.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://franks-travelbox.com/wp-content/uploads/2020/05/sri-lanka-der-hocc88hlentempel-von-dambulla-stammt-aus-den-ersten-jahrhunderten-vor-christus-und-ist-der-besterhaltene-buddhistische-hocc88hlentempel-des-landes-sri-lanka-sean-hsu-shutte-1200x800.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default SigiriyaCarousel