import React from 'react'
import Hero from './Hero'
import Details from './Details';
import Icons from './Icons';
import Overview from './Overview';
import Topboxes from './Topboxes';
import Gallary8 from './Gallary8';
import Itinery from './Itinery';
import Reviews from './Reviews';
import Footer from '../Maipage.js/Footer';
import Booktour from './Booktour';

const Tendaystour = () => {
  return (
    <div>
      <Hero />
      {/* <Icons /> */}
      {/* <Topboxes /> */}
      <Overview />
      <Itinery />
      {/* <Details /> */}
      {/* <Gallary8 />
      <Reviews /> */}
      <Booktour tour="Машина с водителем" />
      <Footer />
    </div>
  );
}

export default Tendaystour