import React from "react";

const Hero = () => {
  return (
    <div className="container-fluid hero-background-minneriya d-flex">
      <div className="container d-flex m-auto">
        <div className="m-auto d-flex">
          <h1 className="my-auto big-text text-center">
            Джип Сафари <br /> в <br /> Миннерия / Хурулу Парке
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Hero;
