import React from 'react'
import { Carousel } from "react-bootstrap";

const SafariCarousel = () => {
  return (
    <Carousel className="col-12 p-0" controls={false} indicators={false} fade>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://cinnamonweb.blob.core.windows.net/cinnamonweb-prd/dining_wellness/yala-national-park-1090-2710.jpeg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://554a875a-71dc-4f5f-b6bf-ae8967f137d5.selcdn.net/thumbs2/b5ac8bf4-43f5-11ec-b808-727368d7f6d9.870x490.jpg"
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://synergyy.com/wp-content/uploads/2016/04/Indian-Peacock-at-Udawalawa-National-Park-Sri-lanka.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://danutoursandtravels.com/wp-content/uploads/2018/11/yala2.png"
          alt="Forth slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousal-height"
          src="https://avatars.dzeninfra.ru/get-zen_doc/271828/pub_657562dc254ced40862e0899_6575b0675fdde42ef64a87e9/scale_1200"
          alt="Fifth slide"
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default SafariCarousel